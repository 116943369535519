import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { fetchComments, updateComments } from "../../api/community";
import { Button, Pagination, Table, Flex, Select, Space, Input, Popconfirm, Tag, Modal, Tooltip, message, DatePicker, Popover } from "antd";
import { CloseCircleOutlined, EyeFilled, InfoCircleOutlined } from "@ant-design/icons";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import { RichText } from "@chatui/core";
import '../../style/community.css';
import UserInfoView from "../../components/community/user_info_view";
import { PostStatusOptions } from ".";
import PostReview from "../../components/community/post_review";

const CommentsScreen = () => {
    const location = useLocation();
    const tooltipRef = useRef(null);
    const [currPost, setCurrPost] = useState();
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Comment',
            dataIndex: 'content',
            key: 'content',
            render: (_, record) => {
                return (
                    <div>
                        <UserInfoView user={record.user} showWallet={false} />
                        <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, maxWidth: 240, marginTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className="community-content">
                                {Array.from(record.resources ?? []).length > 0 ? '「IMAGE」':''}
                                <RichText content={record.title ? record.title : record.content} />
                            </div>
                            <Popover
                                placement="right"
                                title=''
                                content={
                                    <div className="post-content-review" style={{ maxWidth: 300, maxHeight: 400, overflowY: 'auto' }}>
                                        <RichText content={record.content} />
                                        {
                                            Array.from(record.resources ?? []).map(img => {
                                                return <img key={img.id} alt="addx go comment image" src={img.url} style={{width: '100%', height: 'auto', marginTop: 8}} /> 
                                            })
                                        }
                                    </div>
                                }
                                trigger={'click'}>
                                <EyeFilled style={{ color: 'grey', cursor: 'pointer' }} />
                            </Popover>
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Related Post',
            dataIndex: 'post',
            key: 'post',
            render: (_, record) => {
                if (!record.post || !record.post?.user) return '-';
                return (
                    <div>
                        <UserInfoView user={record.post?.user} showWallet={false} />
                        <div style={{ background: '#fafafa', padding: '8px 12px', borderRadius: 8, maxWidth: 240, marginTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className="community-content">
                                <RichText content={record.post.title ? record.post.title : record.post.content} />
                            </div>
                            <EyeFilled style={{ color: 'grey', cursor: 'pointer' }} onClick={() => reviewHandle(record.post)} />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Likes',
            dataIndex: 'agreeCount',
            key: 'agreeCount',
            render: (text, _) => {
                if (parseFloat(text) <= 0) return '-';
                return <b>{text}</b>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (String(text) === '3') {
                    if (record.riskReason) {
                        return (
                            <Tooltip ref={tooltipRef} title={record.riskReason} color='orange'>
                                <Tag color="red">RISK CONFIRM <CloseCircleOutlined /></Tag>
                            </Tooltip>
                        )
                    }
                    return <Tag color="orange">RISK PENDING <InfoCircleOutlined /></Tag>
                } else if (String(text) === '2') {
                    return <Tag style={{ fontSize: 12 }} color="processing">PENDING</Tag>;
                }
                return <Tag style={{ fontSize: 12 }}>PUBLISHED</Tag>;
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        {
                            (String(record.status) === '3' || String(record.status) === '2' ?
                                (record.riskReason ?
                                    <Button type='link' onClick={() => { revokeHandle(record) }}>Revoke</Button>
                                    :
                                    <>
                                        <Button type='link' onClick={() => { revokeHandle(record) }}>Approve</Button>
                                        <Button danger type='link' onClick={() => { riskHandle(record) }}>Reject</Button>
                                    </>
                                ) :
                                <Button danger type='link' onClick={() => { riskHandle(record) }}>Reject</Button>
                            )
                        }
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this comment?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        content: '',
        status: '',
    });
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);
    }, [location])

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchComments(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                let data = [...res.list];
                data.forEach(item => {
                    delete item['children'];
                });
                setTabData(data);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const deleteHandle = (item) => {
        setLoading(true);
        updateComments({ id: item.id, delete: true }).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => message.error(String(err))).finally(() => {
            setLoading(false);
        });
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            newParams[tag] = value;
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const reviewHandle = (post) => {
        setCurrPost(post);
        setOpen(true);
    }

    const riskHandle = (record) => {
        let reason = '';
        let modal = Modal.confirm({
            closable: false,
            title: 'Are you sure to reject this comment?',
            content: (
                <div>
                    <Input style={{ color: 'red' }} prefix={<span style={{ color: '#333333' }}>Reason: </span>} placeholder="reject reason" onChange={evt => {
                        reason = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !reason.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !reason.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    await updateComments({ id: record.id, riskReason: reason, status: 3, delete: false });
                    fetchData(pageNum, pageSize, searchParams);
                } catch (error) {
                    message.error(String(error));
                }
            }
        })
    }

    const revokeHandle = (record) => {
        setLoading(true);
        let params = { id: record.id, status: 1, riskReason: '', delete: false };
        updateComments(params).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify="space-between">
                    <div />
                    <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <Select
                            style={{ width: 160 }}
                            options={PostStatusOptions}
                            placeholder='Post status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('content', evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <PostReview
                from="comment"
                open={open}
                post={currPost}
                onClose={() => setOpen(false)}
                canEdit={false}
            />
        </div>
    )
}

export default CommentsScreen;