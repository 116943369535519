import { List, Space, Tag, Avatar, Typography, Spin } from "antd";
import { capitalizeFirstLetter } from "../../utils/comm";
import { useState, useEffect } from 'react';
import { fetchUserBalance } from "../../api/community";

const AssetsBalanceView = ({ user }) => {

    const [balances, setBalances] = useState(null);

    useEffect(() => {
        if(user) {
            const {userId} = user;
            fetchUserBalance({userIds: userId}).then(res => {
                setBalances(res.list ? res.list : [])
            }).catch(err => {
                setBalances([]);
            });
        }
    }, [user])
    const getChainIcon = (chain) => {
        if (String(chain).toLowerCase() === 'bitcoin') {
            return '/images/btc.svg';
        } else if (String(chain).toLowerCase() === 'polygon') {
            return '/images/matic.svg'
        } else if (String(chain).toLowerCase() === 'ethereum') {
            return '/images/eth.svg'
        }
        console.log('chain', chain);
        return '';
    }
    const getBalances = (datas) => {
        return (
            <Space wrap>
                {
                    datas.map((item, index) => {
                        return <Tag key={`${item.symbol}_${index}`}>{`${item.balance} ${item.symbol}`}</Tag>
                    })
                }
            </Space>
        )
    }

    if(balances == null) {
        return <Spin />
    }

    let arr = balances.filter(item => {
        return item.list.some(token => parseFloat(token.balance) > 0);
    }).map(item => {
        let list = item.list.filter(token => parseFloat(token.balance) > 0);
        return { ...item, list: list };
    });
    if (arr.length <= 0) return <Typography.Paragraph>No Balance</Typography.Paragraph>;

    return (
        <div style={{ minWidth: 400, maxWidth: 500, marginTop: 30 }}>
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={arr}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar alt="" src={getChainIcon(item.chain)} size={32} />}
                            title={capitalizeFirstLetter(item.chain)}
                            description={getBalances(item.list)}
                        />
                    </List.Item>
                )}
            />
        </div>
    )
}

export default AssetsBalanceView;