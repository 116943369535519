import { Descriptions, Input, Button, message, Select, Switch } from 'antd';
import { CloudStorageImageUploader } from '../upload';
import RichTextEditor from '../editor';
import { UploadOutlined } from '@ant-design/icons';
import { updateCourseDetail } from '../../api/course';
import { useEffect, useMemo, useState } from 'react';

const BasicCourseView = ({ catalogId, chapterId, defaultCourse, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        content: '',
        estimateTime: 0,
        hasQuestion: false,
        maxAnswerTimes: 0,
        maxQuestionCount: 0,
        reward: 0,
        title: '',
        url: '',
        category: null,
        public: false,
    });

    useEffect(() => {
        if (defaultCourse) {
            setData(defaultCourse);
        }
    }, [defaultCourse])

    const onUpdate = (files) => {
        let newData = { ...data };
        if (files[0]) {
            newData.url = files[0].remoteUrl ?? files[0].url
        } else {
            newData.url = '';
        }
        setData(newData);
    }

    const onFormChange = (value, flag) => {
        let updated = { ...data };
        updated[flag] = value;
        setData(updated);
    }

    const lessonsUploadProps = {
        buttonNode: <Button icon={<UploadOutlined />}>Upload</Button>,
        name: 'course_uploader',
        showUploadList: true,
        defaultFileList: useMemo(() => {
            return data.url ? [{
                name: (decodeURIComponent(data.url).split('/').pop() ?? '').split('?')[0],
                url: data.url,
                percent: 100,
            }] : [];
        }, [data.url]),
        listType: 'picture',
        isMultiple: false,
        bucketPath: '/course',
        onUpdate: onUpdate,
    }

    const toSubmit = () => {
        setLoading(true);
        let params = { ...data };
        if (!params.id) {
            params['catalogId'] = catalogId;
            params['chapterId'] = chapterId;
        }
        updateCourseDetail({ list: [params] }).then(res => {
            message.success('updated!');
            onClose();
        }).catch((error) => {
            message.error(String(error));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div>
            <div style={{ height: 30 }} />
            <Descriptions size='large' bordered column={2}>
                <Descriptions.Item label='Title' span={2}>
                    <Input placeholder="title" value={data.title} variant='filled' onChange={(evt) => onFormChange(evt.target.value, 'title')} />
                </Descriptions.Item>
                <Descriptions.Item label='Lock Status' span={2}>
                    <Switch value={data.public} checkedChildren='Unlock' unCheckedChildren='Lock' onChange={(value) => onFormChange(value, 'public')} />
                </Descriptions.Item>
                <Descriptions.Item label='Estimate Read Time' span={1}>
                    <Input placeholder="estimate time" value={data.estimateTime} variant='filled' onChange={(evt) => onFormChange(evt.target.value, 'estimateTime')} type="number" suffix='Mins' />
                </Descriptions.Item>
                <Descriptions.Item label='Max Answer Times' span={1}>
                    <Input disabled={!data.hasQuestion} placeholder="max answer times" value={data.hasQuestion ? data.maxAnswerTimes : '-'} variant='filled' onChange={(evt) => onFormChange(evt.target.value, 'maxAnswerTimes')} />
                </Descriptions.Item>
                <Descriptions.Item label='Max Question Count' span={1}>
                    <Input disabled={!data.hasQuestion} placeholder="max question count" value={data.hasQuestion ? data.maxQuestionCount : '-'} variant='filled' onChange={(evt) => onFormChange(evt.target.value, 'maxQuestionCount')} />
                </Descriptions.Item>
                <Descriptions.Item label='Reward' span={1}>
                    <Input disabled={!data.hasQuestion} placeholder="answer rewards" value={data.hasQuestion ? data.reward : '-'} variant='filled' onChange={(evt) => onFormChange(evt.target.value, 'reward')} />
                </Descriptions.Item>
                <Descriptions.Item label='Video or Other Media' span={2}>
                    <div style={{ maxHeight: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <CloudStorageImageUploader {...lessonsUploadProps} />
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label='Course Media Type' span={2}>
                    <Select
                        placeholder='select related media type'
                        value={data.category}
                        style={{ width: '100%' }}
                        onChange={(value) => onFormChange(value, 'category')}
                        options={[
                            { value: 0, label: 'Rich Text only' },
                            { value: 1, label: 'Video contained' },
                            { value: 2, label: 'Audio contained' },
                        ]}
                    />
                </Descriptions.Item>
                <Descriptions.Item label='Content' span={2}>
                    <RichTextEditor
                        contentField={'content'}
                        defaultContent={data.content}
                        defaultHeight={500}
                        onUpdate={(key, value) => onFormChange(value, key)} />
                </Descriptions.Item>
            </Descriptions>
            <div className="btn-area">
                <Button loading={loading} type="primary" style={{ width: 200, height: 44 }} onClick={toSubmit}>Submit</Button>
                <div style={{ width: 24 }} />
                <Button loading={loading} block style={{ width: 200, height: 44 }} onClick={onClose}>Cancel</Button>
            </div>
        </div>
    )
}

export default BasicCourseView;