import { useEffect, useState } from 'react';
import { createInviteCode, deleteInviteCode, getInviteCode, updateInviteCode } from "../../api/system";
import { Table, message, Typography, Flex, Button, Popconfirm } from 'antd';
import { getRootUrl } from '../../utils/base_url';


const InviteCodeView = () => {
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => (index + 1),
        },
        {
            title: 'Code',
            dataIndex: 'inviteCode',
            key: 'inviteCode',
            render: (text, record) => {
                let val = record.inviteCode;
                return <Typography.Text editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, inviteCode: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
            width: '20%',
        },
        {
            title: 'Total Quota',
            dataIndex: 'total',
            key: 'total',
            render: (text, record) => {
                let val = record.total;
                return <Typography.Text editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, total: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
            width: '20%',
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            render: (text, record) => {
                let val = record.platform;
                return <Typography.Text editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, platform: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
        },
        {
            title: 'Used Quota',
            dataIndex: 'used',
            key: 'used',
        },
        {
            title: 'Referral Link',
            dataIndex: 'inviteCode',
            key: 'inviteCode',
            render: (text, record) => {
                let val = record.inviteCode;
                return <Typography.Text copyable>{`${getRootUrl()}/referral?code=${text}`}</Typography.Text>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this invite code?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    const [tabData, setTabData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        getInviteCode({'page.disable': true}).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const deleteHandle = (item) => {
        if(parseInt(item.id) <= 0) {
            setTabData([...tabData].filter(old => old !== item));
            return;
        }
        setLoading(true);
        deleteInviteCode(item.id).then(res => {
            message.success('deleted!');
            setLoading(false);
            fetchData();
        }).catch((err) => {
            message.error(String(err));
            setLoading(false);
        })
    }

    const editHandle = (params) => {
        setLoading(true);
        if(parseInt(params.id) > 0) {
            updateInviteCode(params).then(res => {
                message.success('updated!');
                fetchData();
            }).catch(err => {
                fetchData();
            }).finally(() => setLoading(false));
        } else {
            let newParams = {...params};
            delete newParams['id'];
            createInviteCode(newParams).then(res => {
                message.success('created!');
                fetchData();
            }).catch(err => {
                fetchData();
            }).finally(() => setLoading(false));
        }
        
    }

    const addNewInviteCode = () => {
        if(tabData.findIndex(item => item.id === '-1') >= 0) return;
        setTabData([
            {
                id: '-1',
                inviteCode: '',
                platform: '',
            }, ...tabData
        ]);
    }

    return (
        <div>
            <Flex justify='flex-end' style={{paddingBottom: 20, marginRight: 20}}>
                <Button type='primary' onClick={addNewInviteCode}>Add New InviteCode</Button>
            </Flex>
            <Table
                scroll={{ x: true }}
                loading={loading}
                rowKey={(record) => record.id}
                rowClassName={'table-row'}
                size='small'
                dataSource={tabData}
                columns={tabCols}
                pagination={false} />
        </div>
    )
}

export default InviteCodeView;