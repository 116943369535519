import { Input, Button, message, Select, Form, Row, Col, Drawer, Space, Avatar, Spin, Typography, Radio } from 'antd';
import { fetchTags } from '../../api/community';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { createHomePageHotTag, updateHomePageHotTag } from '../../api/homepage';
import { conversionUtcDate } from '../../utils/comm';

const HotTagEditView = ({ open, defaultTag, onClose, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [form] = Form.useForm();
    const [topics, setTopics] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        setData(defaultTag);

        form.setFieldValue('search', null);
        if (defaultTag) {
            form.setFieldValue('tagId', defaultTag.tag.id)
            form.setFieldValue('name', `#${defaultTag.tag.name}`)
            form.setFieldValue('weight', defaultTag.weight);
            form.setFieldValue('status', String(defaultTag.status).toLowerCase() === 'active' ? 0 : 1);
        } else {
            resetFields();
        }
        getPosts('');
    }, [defaultTag]);

    const resetFields = () => {
        form.setFieldValue('search', null);
        form.setFieldValue('tagId', '')
        form.setFieldValue('name', '')
        form.setFieldValue('weight', '');
        form.setFieldValue('status', 0);
    }

    const onSubmit = () => {
        let params = {
            ...data,
            tagId: form.getFieldValue('tagId'),
            name: String(form.getFieldValue('name')).replace('#', ''),
            weight: form.getFieldValue('weight'),
            status: form.getFieldValue('status') ? parseInt(form.getFieldValue('status')) : 0,
        };
        delete params['post'];
        if (!params.tagId || !params.weight) {
            message.warning('Incomplete information');
            return;
        }
        setLoading(true);
        if (params.id) {
            updateHomePageHotTag(params).then(res => {
                resetFields();

                onClose();
                onUpdate();
            }).catch(err => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createHomePageHotTag(params).then(res => {
                resetFields();

                onClose();
                onUpdate();
            }).catch(err => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const onSelectChange = (value) => {
        form.setFieldValue('tagId', value);
        let tag = topics.find(item => item.value === value);
        if (tag) {
            form.setFieldValue('name', `#${tag.label}`);
        }
    }

    const handleSearch = (value) => {
        getPosts(value);
    }

    const getPosts = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'tag': keyword,
            }
            setSearchLoading(true);
            fetchTags(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        createdAt: conversionUtcDate(item.createdAt, null, true),
                        label: item.name,
                        value: item.id,
                    }));
                    setTopics(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    return (
        <div>
            <Drawer
                forceRender={true}
                title={defaultTag ? 'Edit hot topic' : 'Add hot topic'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='search' label='Search Topic'>
                                <Select
                                    style={{ width: '100%' }}
                                    size='large'
                                    placeholder='you can input keywords to search topic'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={topics}
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                <div style={{ flex: 1 }}>
                                                    <b style={{ wordBreak: 'break-word' }}>{option.data.label}</b>
                                                    <div>
                                                        <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                            {option.data.createdAt}
                                                        </Typography.Text>
                                                    </div>
                                                </div>
                                            </Space>
                                        )
                                    }}
                                    onChange={onSelectChange}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ height: 36, width: 200 }} />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='tagId' label='Topic ID'>
                                <Input placeholder="please input tag id" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='name' label='Topic Name'>
                                <Input.TextArea autoSize disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='weight' label='Weight'>
                                <Input placeholder="recommend weight" type='number' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='status' label='Status'>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Active</Radio.Button>
                                    <Radio.Button value={1}>Inactive</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export default HotTagEditView;