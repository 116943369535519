import { useEffect, useState } from "react"
import { Drawer, message, Button, Collapse, Flex, Modal, Input, Popconfirm, Space, Spin } from 'antd';
import { CaretRightFilled, DeleteTwoTone, EditTwoTone, LoadingOutlined, LockTwoTone, UnlockFilled, DeleteFilled } from "@ant-design/icons";
import { theme } from "antd";
import { deleteChapters, deleteCourseItems, getCourseChapters, updateChapters, updateCourseDetail } from "../../api/course";
import { useNavigate } from "react-router-dom";
import { MainLayoutUrl } from "../../router";

const ChaptesEditView = ({ defaultCatalog, defaultActiveKey, open, onClose, onUpdate }) => {
    const navigateTo = useNavigate();
    const [catalog, setCatalog] = useState();
    const [loading, setLoading] = useState(false);
    const [chapters, setChapters] = useState([]);
    const { token } = theme.useToken();
    const [curChapter, setCurChapter] = useState();
    const [editModalOpen, setEditModalOpen] = useState(false);

    const panelStyle = {
        marginBottom: 12,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none',
    };

    const getExtra = (item) => (
        <>
            <Space>
                <EditTwoTone
                    onClick={(event) => {
                        event.stopPropagation();
                        setCurChapter(item);
                        setEditModalOpen(true);
                    }}
                />

                <Popconfirm trigger='click' title='Delete this chapter?' okText='Yes' cancelText='No' onConfirm={() => deleteChapter(item)}>
                    <DeleteTwoTone twoToneColor="#eb2f96" onClick={(event) => {
                        event.stopPropagation();
                    }} />
                </Popconfirm>
            </Space>
        </>
    )

    useEffect(() => {
        setCatalog(defaultCatalog);
        if (defaultCatalog) {
            setChapters(defaultCatalog.chapters.map(item => {
                return {
                    key: item.id,
                    label: item.title,
                    children: <LoadingOutlined spin={true} style={{ color: '#1677ff' }} />,
                    style: panelStyle,
                    extra: getExtra(item),
                }
            }))

            fetchChapters(defaultCatalog.id);
        }

    }, [defaultCatalog]);

    const fetchChapters = (catalogId) => {
        getCourseChapters({ 'page.disable': true, 'catalogId': catalogId }).then(res => {
            if (res.list) {
                setChapters(res.list.map(item => {
                    return {
                        key: item.id,
                        label: item.title,
                        children: (item.courses && item.courses.length > 0) ?
                            <div>
                                {
                                    item.courses.map((course, index) => {
                                        return (
                                            <Flex
                                                className="chapter-course-item"
                                                key={course.id}
                                                justify="space-between"
                                                align="center" >
                                                <div style={{ flex: 1, marginRight: 12, cursor: 'pointer' }} onClick={() => toEditCourse(item.id, course.id)}>
                                                    <b>{index + 1}.&nbsp;{course.title}</b>
                                                    <label style={{ color: '#bbb' }}>&nbsp;&nbsp;{course.estimateTime}Mins</label>
                                                </div>
                                                <Space size={0}>
                                                    <div style={{ padding: '4px' }} onClick={(evt) => handleCourseLock(course)} >
                                                        {
                                                            course.public == true ?
                                                                <UnlockFilled style={{ color: '#87d068', fontSize: 14 }} />
                                                                :
                                                                <LockTwoTone style={{ fontSize: 14 }} />
                                                        }
                                                    </div>
                                                    <Popconfirm
                                                        trigger='click'
                                                        title='Delete this couse item?'
                                                        okText='Yes'
                                                        cancelText='No'
                                                        onConfirm={(evt) => deleteCourse(course)} >
                                                        <div style={{ padding: '4px' }} >
                                                            <DeleteFilled style={{ fontSize: 14, color: 'grey' }} />
                                                        </div>
                                                    </Popconfirm>
                                                </Space>
                                            </Flex>
                                        )
                                    })
                                }
                                <Button type="dashed" size="small" style={{ fontSize: 12, marginTop: 20 }} onClick={() => toNewCourse(item.id)}>+ Add New Course</Button>
                            </div> :
                            <Button type="dashed" size="small" style={{ fontSize: 12 }} onClick={() => toNewCourse(item.id)}>+ Add New Course</Button>,
                        style: panelStyle,
                        extra: getExtra(item),
                    }
                }))
            }
        })
    }

    const addNewChapter = () => {
        setCurChapter(null);
        setEditModalOpen(true);
    }

    const toNewCourse = (chapterId) => {
        onClose();
        let courseParam = `${defaultCatalog.id}-${chapterId}-0`;
        navigateTo(MainLayoutUrl.course.item.path.replace(':course', courseParam));
    }

    const toEditCourse = (chapterId, courseId) => {
        onClose();
        let courseParam = `${defaultCatalog.id}-${chapterId}-${courseId}`;
        navigateTo(MainLayoutUrl.course.item.path.replace(':course', courseParam));
    }

    const onChapterInput = (value) => {
        let newChapter = curChapter ? { ...curChapter } : {};
        newChapter['title'] = value;
        setCurChapter(newChapter);
    }

    const handleCourseLock = (course) => {
        if (loading) return;
        setLoading(true);
        updateCourseDetail({ list: [{ ...course, public: course.public ? false : true }] }).then(res => {
            fetchChapters(defaultCatalog.id);
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    };

    const onSumbit = () => {
        if (curChapter == null || !String(curChapter.title).trim()) return;
        let params = { ...curChapter };
        if (!params.id) {
            params['catalogId'] = defaultCatalog.id;
        }
        setLoading(true);
        updateChapters({ list: [params] }).then(res => {
            message.success('updated!');
            setEditModalOpen(false);
            fetchChapters(defaultCatalog.id);
            onUpdate();
        }).catch((err) => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    const deleteChapter = (item) => {
        message.loading('chapter deleting...');
        deleteChapters([item.id]).then(res => {
            message.destroy();
            message.success('deleted!');
            fetchChapters(defaultCatalog.id);
            onUpdate();
        }).catch(err => {
            message.destroy();
            message.error(String(err));
        });
    }

    const deleteCourse = (item) => {
        message.loading('course item deleting...');
        deleteCourseItems([item.id]).then(res => {
            message.destroy();
            message.success('deleted!');
            fetchChapters(defaultCatalog.id);
        }).catch(err => {
            message.destroy();
            message.error(String(err));
        });
    }

    return (
        <Drawer
            title={catalog ? `${catalog.title} - Chapters` : ''}
            width={520}
            maskClosable
            onClose={onClose}
            open={open}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
            extra={
                loading && <Spin />
            }
        >
            <Flex justify="flex-end">
                <Button type="dashed" onClick={() => addNewChapter()}>+ Add New Chapter</Button>
            </Flex>
            <div style={{ height: 18 }} />
            <Collapse
                bordered={false}
                defaultActiveKey={defaultActiveKey ? [defaultActiveKey] : null}
                expandIcon={({ isActive }) => <CaretRightFilled rotate={isActive ? 90 : 0} />}
                style={{ background: token.colorBgContainer }}
                items={chapters}
            />
            <Modal
                open={editModalOpen}
                maskClosable
                title={'Edit Chapter'}
                centered
                width={400}
                okText='Confirm'
                okButtonProps={{ loading: loading }}
                onOk={() => onSumbit()}
                onCancel={() => setEditModalOpen(false)}>
                <div>
                    <Input placeholder="chapter title" value={curChapter ? curChapter.title : ''} onChange={(evt) => onChapterInput(evt.target.value)} />
                </div>
            </Modal>
        </Drawer>
    )
}

export default ChaptesEditView;