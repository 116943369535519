import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { createPost, fetchPostCategory, updatePost } from "../../api/community";
import { Descriptions, Input, Select, Space, Button, message, DatePicker, Tag } from 'antd'
import RichTextEditor from "../../components/editor";
import { CloudStorageImageUploader } from "../../components/upload";
import { MainLayoutUrl } from "../../router";
import dayjs from "dayjs";
import { conversionUtcDate } from "../../utils/comm";
import '../../style/community.css';
import VoteItemsEditView from "../../components/community/vote_items_edit_view";
import { customUploaderBtn } from "../../components/comm/custom_uploader_btn";

const CommunityDetailScreen = () => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const [data, setData] = useState(location.state && location.state.detail ? JSON.parse(location.state.detail) : {});
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPostCategory().then(res => {
            if (res.list) {
                setCategories(res.list.map(item => ({ key: item.id, label: item.name, value: item.id })))
            }
        })
    }, [])

    const onFormChange = (tag, value) => {
        let newData = { ...data };
        if (tag.includes('reward')) {
            let reward = { ...(data.reward ?? {}) }
            if (tag.includes('count')) {
                reward['count'] = value;
            } else if (tag.includes('endAt')) {
                reward['endAt'] = conversionUtcDate(value, 'UTC');
            } else if (tag.includes('desc')) {
                reward['description'] = value;
            } else if (tag.includes('tip')) {
                reward['tip'] = value;
            } else if (tag.includes('rule')) {
                reward['rule'] = value;
            } else if (tag.includes('symbol')) {
                reward['symbol'] = value;
            }
            newData['reward'] = reward;
        } else if (tag.includes('vote')) {
            let vote = { ...(data.vote ?? {}) }
            if (tag.includes('items')) {
                vote['options'] = [...value];
            } else if (tag.includes('desc')) {
                vote['description'] = value;
            } else if (tag.includes('endAt')) {
                vote['endAt'] = conversionUtcDate(value, 'UTC');
            }
            if (!vote.id || parseInt(vote.id) <= 0) {
                delete vote['id'];
            }
            newData['vote'] = vote;
        } else {
            if (tag === 'content') {
                let ele = document.createElement('div');
                ele.innerHTML = value;
                let allTags = ele.innerText.match(/(?<=#).*?(?=\s)/g);
                if (allTags && allTags.length > 0) {
                    allTags = Array.from(new Set(allTags));
                    newData['tag'] = allTags.join(',');
                }
            }
            newData[tag] = value;
        }

        setData(newData);
    }

    const deleteTag = (tag) => {
        let newData = { ...data };

        let content = newData['content'];
        content = String(content).replace(new RegExp(`#${tag}`, 'g'), '');
        newData['content'] = content;

        let tagStr = newData['tag'];
        tagStr = (tagStr ?? '').split(',').filter(item => item !== tag).join(',');
        newData['tag'] = tagStr;

        setData(newData);
    }

    const onImageUpdate = (files) => {
        let newData = { ...data };
        newData.resource = (files ?? []).map(item => (item.remoteUrl ? item.remoteUrl : item.url)).join(',');
        setData(newData);
    }

    const onTagCoverImageUpdate = (files) => {
        let newData = { ...data };
        let reward = { ...newData.reward };
        newData.reward = { ...reward, cover: files[0] ? (files[0].remoteUrl ? files[0].remoteUrl : files[0].url) : '' }
        setData(newData);
    }

    const toSubmit = () => {
        setLoading(true);
        let params = { ...data };
        if (params.id) {
            updatePost(params.id, params).then(res => {
                message.success('updated!');
                onClose();
            }).catch((error) => {
                message.error(String(error));
            }).finally(() => {
                setLoading(false);
            });
        } else {
            createPost(params).then(res => {
                message.success('updated!');
                onClose();
            }).catch((error) => {
                message.error(String(error));
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    const onClose = () => {
        navigateTo(MainLayoutUrl.community.post.index.path);
    }

    const postMediaUploadProps = {
        defaultFileList: useMemo(() => {
            return (data.resource ?? '').split(',').filter(item => item).map(item => ({
                name: (decodeURIComponent(item).split('/').pop() ?? '').split('?')[0],
                url: item,
                percent: 100,
            }));
        }, [data.resource]),
        name: 'community-uploader',
        bucketPath: '/community',
        listType: 'picture-card',
        isMultiple: true,
        needItemRender: true,
        useCardPreview: true,
        showUploadList: true,
        buttonNode: customUploaderBtn('Upload Media'),
        onUpdate: onImageUpdate
    }

    const tagCoverUploadProps = {
        defaultFileList: useMemo(() => {
            return data.reward && data.reward.cover ? [{
                name: (decodeURIComponent(data.reward.cover).split('/').pop() ?? '').split('?')[0],
                url: data.reward.cover,
                percent: 100,
            }] : [];
        }, [data.reward?.cover]),
        name: 'tag-cover-uploader',
        bucketPath: '/tags',
        listType: 'picture-card',
        isMultiple: false,
        needItemRender: true,
        useCardPreview: true,
        showUploadList: true,
        buttonNode: customUploaderBtn('Upload Cover'),
        onUpdate: onTagCoverImageUpdate
    }

    return (
        <div style={{ padding: '20px 40px' }}>
            <div className="title">{id ? (id === '0' ? 'Create New Post' : 'Edit Post') : ''}</div>
            <div style={{ height: 30 }} />
            <Descriptions size='large' bordered column={2} labelStyle={{ width: 250, textAlign: 'center' }}>
                <Descriptions.Item label='Post Category' span={1}>
                    <Select value={data.categoryId} style={{ width: '100%' }} options={categories} placeholder='Select category' onChange={value => onFormChange('categoryId', value)} />
                </Descriptions.Item>
                <Descriptions.Item label='Recommend Weight' span={1}>
                    <Input value={data.recommend} type="number" placeholder="article recommend weight" onChange={evt => onFormChange('recommend', evt.target.value)} />
                </Descriptions.Item>
                <Descriptions.Item label='Title' span={2}>
                    <Input value={data.title} placeholder="article title" onChange={evt => onFormChange('title', evt.target.value)} allowClear />
                </Descriptions.Item>
                {
                    (data.categoryId && categories.length > 0 && categories.find(item => item.value === data.categoryId).label.toLowerCase() === 'post') &&
                    <Descriptions.Item label='Post Media' span={2}>
                        <Space align="center">
                            <CloudStorageImageUploader {...postMediaUploadProps} />
                        </Space>
                    </Descriptions.Item>
                }
                <Descriptions.Item label='Content' span={2}>
                    <RichTextEditor
                        defaultHeight={300}
                        defaultContent={data.content}
                        contentField='content'
                        onUpdate={(field, result) => onFormChange(field, result)} />
                </Descriptions.Item>
                <Descriptions.Item label='Post Tags' span={2}>
                    <Space wrap={true}>
                        {
                            (data.tag ?? '').split(',').filter(item => item).map((item, index) => {
                                return <Tag color="blue" key={index} closable onClose={(evt) => {
                                    evt.preventDefault();
                                    deleteTag(item);
                                }}>{`#${item}`}</Tag>
                            })
                        }
                    </Space>
                </Descriptions.Item>
                {
                    (data.tag ?? '').split(',').filter(item => item).length > 0 &&
                    <Descriptions.Item label='Bounty Rewards' span={2}>
                        <div className="reward">
                            <Descriptions size="small" column={1} layout="vertical" colon={false} labelStyle={{ marginBottom: 0 }}>
                                <Descriptions.Item span={1} label='Reward Amounts'>
                                    <Input
                                        style={{ width: 200 }}
                                        variant="filled"
                                        value={data.reward ? data.reward.count : ''}
                                        type="number"
                                        placeholder="reward count"
                                        onChange={evt => onFormChange('reward_count', evt.target.value)}
                                        allowClear
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item span={1} label='Reward Symbol'>
                                    <Input
                                        style={{ width: 200 }}
                                        variant="filled"
                                        value={data.reward ? data.reward.symbol : ''}
                                        placeholder="reward symbol"
                                        onChange={evt => onFormChange('reward_symbol', evt.target.value)}
                                        allowClear
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item span={1} label='End At'>
                                    <DatePicker
                                        variant="filled"
                                        showTime
                                        minDate={data.createdAt ? dayjs(data.createdAt).add(1, 'day') : dayjs(Date.now()).add(1, 'day')}
                                        defaultValue={(data.reward && data.reward.endAt) ? dayjs(data.reward.endAt) : ''}
                                        format={(value) => `${value ? conversionUtcDate(value) : ''}`}
                                        placeholder="End At"
                                        onChange={(_, date) => onFormChange('reward_endAt', date.replace('End At: ', ''))}
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label='Cover' span={1}>
                                    <CloudStorageImageUploader {...tagCoverUploadProps} />
                                </Descriptions.Item>
                                <Descriptions.Item label='Descrption' span={1}>
                                    <Input.TextArea
                                        style={{ width: 400 }}
                                        placeholder="reward descrption"
                                        value={data.reward ? data.reward.description : ''}
                                        rows={2}
                                        variant="filled"
                                        onChange={evt => onFormChange('reward_desc', evt.target.value)}
                                        allowClear
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label='Tips' span={1}>
                                    <Input.TextArea
                                        style={{ width: 400 }}
                                        placeholder="reward tips"
                                        value={data.reward ? data.reward.tip : ''}
                                        rows={2}
                                        variant="filled"
                                        onChange={evt => onFormChange('reward_tip', evt.target.value)}
                                        allowClear
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label='Rules' span={1}>
                                    <Input.TextArea
                                        style={{ width: 400 }}
                                        placeholder="reward rules"
                                        value={data.reward ? data.reward.rule : ''}
                                        rows={2}
                                        variant="filled"
                                        onChange={evt => onFormChange('reward_rule', evt.target.value)}
                                        allowClear
                                    />
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Descriptions.Item>
                }
                <Descriptions.Item label='Votes Config' span={2}>
                    <div className="reward">
                        <Descriptions size="small" column={1} layout="vertical" colon={false} labelStyle={{ marginBottom: 0 }}>
                            <Descriptions.Item label='Vote Desc' span={1}>
                                <Input
                                    style={{ width: 400 }}
                                    placeholder="vote description"
                                    value={data.vote ? data.vote.description : ''}
                                    variant="filled"
                                    onChange={evt => onFormChange('vote_desc', evt.target.value)}
                                    allowClear
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='Vote EndAt' span={1}>
                                <DatePicker
                                    variant="filled"
                                    showTime
                                    minDate={data.vote && data.vote.createdAt ? dayjs(data.vote.createdAt).add(1, 'day') : dayjs(Date.now()).add(1, 'day')}
                                    defaultValue={(data.vote && data.vote.endAt) ? dayjs(data.vote.endAt) : ''}
                                    format={(value) => `${value ? conversionUtcDate(value) : ''}`}
                                    placeholder="End At"
                                    onChange={(_, date) => onFormChange('vote_endAt', date)}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='Vote Items' span={1}>
                                <VoteItemsEditView defaultTags={data.vote && data.vote.options ? data.vote.options : []} onUpdate={(value) => onFormChange('vote_items', value)} />
                            </Descriptions.Item>
                        </Descriptions>
                    </div>

                </Descriptions.Item>
            </Descriptions>
            <div className="btn-area">
                <Button loading={loading} type="primary" style={{ width: 200, height: 44 }} onClick={toSubmit}>Submit</Button>
                <div style={{ width: 24 }} />
                <Button loading={loading} block style={{ width: 200, height: 44 }} onClick={onClose}>Cancel</Button>
            </div>
        </div>
    )
}

export default CommunityDetailScreen;