import { Form, Input, Flex, Button, message, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { createTagUserRewards, updateTagUserReward } from "../../api/community";
import { CloseOutlined } from "@ant-design/icons";
import UserInfoView from "./user_info_view";


const RewardEditModalView = ({ users, open, onClose, onUpdate, isEdit = false }) => {
    const [loading, setLoading] = useState(false);
    const [rewardForm] = Form.useForm();

    useEffect(() => {
        if (users) {
            rewardForm.setFieldValue('users', users.map(item => ({ ...item, key: item.id, chain: (item.chain ? item.chain : null) })));
        } else {
            rewardForm.setFieldValue('users', null);
        }
    }, [users]);

    useEffect(() => {
        if (!open) {
            rewardForm.setFieldValue('users', null);
        }
    }, [open])

    const onSubmit = () => {
        let editUsers = rewardForm.getFieldValue('users');
        if (!editUsers || editUsers.length <= 0) return;
        setLoading(true);
        if (isEdit) {
            let record = editUsers[0];
            let params = {
                id: record.id,
                userId: record.user.userId,
                txId: record.txId,
                tag: record.tag.name,
                symbol: record.symbol,
                amount: record.amount,
                postId: record.postId,
                chain: record.chain,
            }
            updateTagUserReward(params).then(res => {
                message.success('updated!');
                onClose();
                onUpdate();
            }).catch((err) => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            });
        } else {
            let params = {
                list: editUsers.map(item => {
                    let newdata = { ...item };
                    delete newdata['user'];
                    return newdata;
                }),
            }
            createTagUserRewards(params).then(res => {
                message.success('updated!');
                onClose();
                onUpdate();
            }).catch((err) => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    return (
        <Modal
            maskClosable
            open={open}
            footer={[
                <Button key="back" loading={loading} onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
                    Submit
                </Button>,
            ]}
            width={1080}
            onCancel={onClose}
            title='Send Rewards To Users'
            styles={{
                body: {
                    padding: '40px 0 20px 0'
                }
            }}
        >
            <Form form={rewardForm} layout="vertical">
                <Form.List name='users'>
                    {(subFields, subOpt) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 24 }}>
                                {subFields.map((subField, index) => {
                                    let userItem = (rewardForm.getFieldValue('users') ?? [])[index];
                                    if (!userItem) return <div />;
                                    return <Flex key={subField.key} style={{ width: '100%' }} align="center">
                                        <UserInfoView user={userItem.user} showWallet={true} />
                                        <Flex align="flex-end" style={{ flex: 1 }}>
                                            <div style={{ marginLeft: 20 }}>
                                                <Form.Item label='Chain' noStyle name={[subField.name, 'chain']}>
                                                    <Select options={[
                                                        { key: 1, label: 'Bitcoin', value: 'bitcoin' },
                                                        { key: 2, label: 'Polygon', value: 'polygon' },
                                                        { key: 3, label: 'Ethereum', value: 'ethereum' },
                                                    ]} style={{ width: 120, border: 'none', borderBottom: '1px solid #eee' }} variant="borderless" placeholder="Chain" allowClear />
                                                </Form.Item>
                                            </div>
                                            <div style={{ flex: 1, marginLeft: 20, marginRight: 4 }}>
                                                <Form.Item label='TxId' noStyle name={[subField.name, 'txId']}>
                                                    <Input.TextArea style={{ border: 'none', borderBottom: '1px solid #eee' }} allowClear placeholder='TxId' autoSize />
                                                </Form.Item>
                                            </div>
                                            <div style={{ marginLeft: 4 }}>
                                                <Form.Item label='Amount' noStyle name={[subField.name, 'amount']}>
                                                    <Input style={{ width: 100, border: 'none', borderBottom: '1px solid #eee' }} allowClear placeholder='Amount' />
                                                </Form.Item>
                                            </div>
                                            <div style={{ marginLeft: 4 }}>
                                                <Form.Item label='Symbol' noStyle name={[subField.name, 'symbol']}>
                                                    <Input style={{ width: 100, border: 'none', borderBottom: '1px solid #eee' }} placeholder="Symbol" allowClear />
                                                </Form.Item>
                                            </div>
                                        </Flex>
                                        <CloseOutlined
                                            style={{ marginLeft: 20 }}
                                            onClick={() => {
                                                subOpt.remove(subField.name);
                                            }}
                                        />
                                    </Flex>;
                                })}
                            </div>
                        );
                    }}
                </Form.List>
            </Form>
        </Modal>
    )
}

export default RewardEditModalView;