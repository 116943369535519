import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { deletePost, fetchPostCategory, fetchPosts, updatePost } from "../../api/community";
import { MainLayoutUrl } from "../../router";
import { Button, Pagination, Table, Flex, Select, Space, Input, Popconfirm, Tag, Modal, Tooltip, message, DatePicker, Popover, Typography } from "antd";
import { CheckOutlined, CloseCircleOutlined, EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import { RichText } from "@chatui/core";
import '../../style/community.css';
import PostReview from "../../components/community/post_review";
import PrizeImg from '../../assets/prize.png';
import UserInfoView from "../../components/community/user_info_view";
import PostLikesView from "../../components/community/post_like_users_view";

export const PostStatusOptions = [
    { key: 0, label: 'PUBLISHED', value: '1' },
    { key: 1, label: 'PENDING', value: '2' },
    { key: 2, label: 'RISK', value: '3' },
];

export const PostTopicOptions = [
    { key: 0, label: 'General', value: '0' },
    { key: 1, label: 'Stocks', value: '1' },
    { key: 2, label: 'PrivateMarket', value: '2' },
    { key: 3, label: 'Web3', value: '3' },
    { key: 4, label: 'Startup', value: '4' },
];

export const PostTopicColor = ['magenta', 'volcano', 'green', 'purple', 'orange'];

const CommunityScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const tooltipRef = useRef(null);
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, _) => {
                if(!text) return '-';
                return <div style={{width: 120}}><b>{text}</b></div>
            },
        },
        {
            title: 'PostId',
            dataIndex: 'id',
            key: 'id',
            render: (text, _) => {
                return <div style={{width: 100}}><Typography.Text copyable style={{fontSize: 12, color: '#afafaf'}}>{text}</Typography.Text></div>
            },
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (text, _) => {
                return <UserInfoView user={text} showWallet={false} />
            },
        },
        {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic',
            render: (text, record) => {
                return (
                    <Popover placement="topLeft" trigger={'click'} title='Choose the post topic here!' content={
                        <Space direction='vertical' style={{ width: '100%', marginTop: 16 }}>
                            {
                                PostTopicOptions.map(item => {
                                    return <Tag.CheckableTag
                                        key={item.key}
                                        checked={(record.topic ?? []).includes(item.label)}
                                        onChange={(checked) => updatePostTopic(record, item.value)} >
                                        {item.label}
                                        &nbsp;&nbsp;
                                        {
                                            (record.topic ?? []).includes(item.label) && <CheckOutlined />
                                        }
                                    </Tag.CheckableTag>;
                                })
                            }
                        </Space>
                    }>
                        <Tag style={{ cursor: 'pointer' }}><b>{record.topic.join(', ')}</b>&nbsp; <EditOutlined /></Tag>
                    </Popover>
                )
            },
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (text, record) => {
                return (
                    <Space size={4} wrap style={{ minWidth: 150 }}>
                        <Tag color={String(text.name).toLowerCase() === 'post' ? 'cyan' : 'blue'} style={{ margin: 0 }}>{text.name}</Tag>
                        {
                            (record.user.admin && record.reward && parseFloat(record.reward.count) > 0 && record.reward.id) && (
                                <Tag color="#f50" style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                                    <img alt='post reward' src={PrizeImg} style={{ width: 16, height: 'auto', marginRight: 2 }} />
                                    <b>Prize</b>
                                </Tag>
                            )
                        }
                        {
                            record.vote && parseInt(record.vote.id ?? '0') > 0 && Array.from(record.vote.options ?? []).length > 0 && (
                                <Tag color="#55acee" style={{ fontStyle: 'italic' }}><b>Vote</b></Tag>
                            )
                        }
                    </Space>
                )
            },
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (_, record) => {
                return (
                    <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, maxWidth: 160 }}>
                        <div className="community-content">
                            <RichText content={record.title ? record.title : record.content} />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (text === 'RISK') {
                    if (record.riskReason) {
                        return (
                            <Tooltip ref={tooltipRef} title={record.riskReason} color='orange'>
                                <Tag color="red">RISK CONFIRM <CloseCircleOutlined /></Tag>
                            </Tooltip>
                        )
                    }
                    return <Tag color="orange">RISK PENDING <InfoCircleOutlined /></Tag>
                }
                return <Tag style={{ fontSize: 12 }}>{text}</Tag>;
            },
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            render: (text, _) => {
                return <div style={{ maxWidth: 200 }}>{text.split(',').filter(item => item).map(item => String(item).indexOf('#') === 0 ? item : `#${item}`).join(', ')}</div>;
            },
        },
        {
            title: 'Recommend Weight',
            dataIndex: 'recommend',
            key: 'recommend',
            render: (text, _) => {
                if (text > 0) return <b>{text}</b>;
                return '-';
            },
            sortDirections: ['descend'],
            sorter: true,
        },
        {
            title: 'Likes',
            dataIndex: 'agreeCount',
            key: 'agreeCount',
            render: (text, record) => {
                if (parseFloat(text) <= 0) return '-';
                return (
                    <Button type="link" onClick={() => toShowLikes(record)}><b>{text}</b></Button>
                );
            },
            sortDirections: ['descend'],
            sorter: true,
        },
        {
            title: 'Comments',
            dataIndex: 'commentCount',
            key: 'commentCount',
            render: (text, _) => {
                if (parseFloat(text) <= 0) return '-';
                return text;
            },
            sortDirections: ['descend'],
            sorter: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { reviewHandle(record) }}>Review</Button>
                        {
                            record.user.admin ?
                                <>
                                    <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                                    <Button type='link' onClick={() => { recommendHandle(record) }}>Recommend</Button>
                                    <Popconfirm
                                        title="Delete Action"
                                        description="Are you sure to delete this post?"
                                        onConfirm={() => { deleteHandle(record) }}
                                        okText="Yes"
                                        cancelText="No" >
                                        <Button danger type='link'>Delete</Button>
                                    </Popconfirm>
                                </>
                                :
                                (record.status === 'RISK' || record.status === 'PENDING' ?
                                    (!record.riskReason && <>
                                        <Button type='link' onClick={() => { revokeHandle(record) }}>Approve</Button>
                                        <Button danger type='link' onClick={() => { riskHandle(record) }}>Reject</Button>
                                    </>) :
                                    <>
                                        <Button type='link' onClick={() => { recommendHandle(record) }}>Recommend</Button>
                                        <Button danger type='link' onClick={() => { riskHandle(record) }}>Reject</Button>
                                    </>
                                )
                        }
                    </Button.Group>
                )
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        keyword: '',
        categoryId: '',
        status: '',
        admin: '',
        gteCreatedAt: '',
        ltCreatedAt: '',
        order: '',
        topic: '',
    });
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currPost, setCurrPost] = useState();
    const [likesDrawerShow, setLikesDrawerShow] = useState(false);

    useEffect(() => {
        fetchPostCategory().then(res => {
            if (res.list) {
                setCategories(res.list.map(item => ({ key: item.id, label: item.name, value: item.id })))
            }
        })
        fetchData(pageNum, pageSize, searchParams);
    }, [location])

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchPosts(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const createNewPost = () => {
        navigate(MainLayoutUrl.community.post.item.path.replace(':id', 0))
    }

    const editHandle = (item) => {
        navigate(MainLayoutUrl.community.post.item.path.replace(':id', item.id), { state: { detail: JSON.stringify(item) } })
    }

    const deleteHandle = (item) => {
        setLoading(true);
        deletePost(item.id).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => message.error(String(err))).finally(() => {
            setLoading(false);
        });
    }

    const toShowLikes = (post) => {
        setCurrPost(post);
        setLikesDrawerShow(true);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.gteCreatedAt = `${value[0]}T00:00:00Z`;
                    newParams.ltCreatedAt = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.gteCreatedAt = '';
                    newParams.ltCreatedAt = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const riskHandle = (record) => {
        let reason = '';
        let modal = Modal.confirm({
            closable: false,
            title: 'Are you sure to reject this post?',
            content: (
                <div>
                    <Input style={{ color: 'red' }} prefix={<span style={{ color: '#333333' }}>Reason: </span>} placeholder="reject reason" onChange={evt => {
                        reason = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !reason.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !reason.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { ...record, status: 'RISK', riskReason: reason };
                try {
                    await updatePost(record.id, params);
                    fetchData(pageNum, pageSize, searchParams);
                } catch (error) {
                    message.error(String(error));
                }
            }
        })
    }

    const revokeHandle = (record) => {
        setLoading(true);
        let params = { ...record, status: 'PUBLISHED', riskReason: '' };
        updatePost(record.id, params).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    const updatePostTopic = (record, topicVal) => {
        setLoading(true);
        topicVal = parseInt(topicVal);
        let topic = PostTopicOptions.filter(item => (record.topic ?? []).includes(item.label)).map(item => parseInt(item.value));
        let index = topic.indexOf(topicVal);
        if (index >= 0) {
            topic = topic.filter(item => item !== topicVal);
        } else {
            topic.push(topicVal);
        }
        let params = { ...record, topic: topic };
        updatePost(record.id, params).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    const recommendHandle = (record) => {
        let weight = String(record.recommend);
        let modal = Modal.confirm({
            closable: false,
            title: 'Recommend the post?',
            content: (
                <div>
                    <Input defaultValue={weight} type="number" prefix={'Recommend Weight: '} placeholder="weight" onChange={evt => {
                        weight = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !weight.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !weight.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { ...record, recommend: weight };
                await updatePost(record.id, params);
                fetchData(pageNum, pageSize, searchParams);
            }
        })
    }

    const reviewHandle = (record) => {
        setCurrPost(record);
        setOpen(true);
    }

    const onTabChange = (pagination, filters, sorter, extra) => {
        let orderValue = '';
        if (sorter.order) {
            orderValue = `${sorter.field}${sorter.order === 'descend' ? ' DESC' : ''}`
        }
        onSearchChange('order', orderValue);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify="space-between">
                    <Button type='primary' icon={<PlusOutlined />} onClick={createNewPost}>Create New Post</Button>
                    <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <DatePicker.RangePicker onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
                        <Select
                            style={{ width: 160 }}
                            options={PostTopicOptions}
                            placeholder='Topic'
                            onChange={value => onSearchChange('topic', value)}
                            allowClear
                        />
                        <Select
                            style={{ width: 160 }}
                            options={categories}
                            placeholder='Category'
                            onChange={value => onSearchChange('categoryId', value)}
                            allowClear
                        />
                        <Select
                            style={{ width: 160 }}
                            options={PostStatusOptions}
                            placeholder='Post status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 0, label: 'ADMIN', value: 'true' },
                                { key: 1, label: 'NOT ADMIN', value: 'false' },
                            ]}
                            placeholder='Is admin post'
                            onChange={value => onSearchChange('admin', value)}
                            allowClear
                        />
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('keyword', evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false}
                    onChange={onTabChange} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <PostReview
                from="community"
                open={open}
                post={currPost}
                onClose={() => setOpen(false)}
                onUpdate={() => fetchData(pageNum, pageSize, searchParams)}
            />

            <PostLikesView
                open={likesDrawerShow}
                post={currPost}
                onClose={() => setLikesDrawerShow(false)} />
        </div>
    )
}

export default CommunityScreen;