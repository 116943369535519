import request from '../utils/request';

export function getSystemConfigs() {
    return request.get('/config');
}

export function updateSystemConfig(params) {
    return request.patch('/config', params);
}

export function getInviteCode(params) {
    return request.get('/invite/code/list', params)
}

export function createInviteCode(params) {
    return request.post('/invite/code/create', params)
}

export function deleteInviteCode(ids) {
    return request.dataDel(`/invite/code/delete?ids=${ids}`);
}

export function updateInviteCode(params) {
    return request.patch('/invite/code/update', params)
}

export function getWhitelist(params) {
    return request.get('/invite/whitelist/list', params);
}

export function createWhitelist(params) {
    return request.post('/invite/whitelist/create', params);
}

export function updateWhitelist(params) {
    return request.patch('/invite/whitelist/update', params);
}

export function deleteWhitelist(ids) {
    return request.dataDel(`/invite/whitelist/delete?ids=${ids}`);
}

export function getAppVersion() {
    return request.get('/app/version')
}

export function updateAppVersion(params) {
    return request.patch('/app/version', params);
}

export function getVersionNotes(params) {
    return request.get('/version/bulletin', params);
}

export function createVersionNote(params) {
    return request.post('/version/bulletin/create', params);
}

export function updateVersionNote(params) {
    return request.patch('/version/bulletin/edit', params);
}

export function deleteVersionNote(id) {
    return request.dataDel(`/version/bulletin?id=${id}`);
}