import { useEffect, useState } from 'react'
import { fetchReportList, updateReport } from '../../api/community';
import { Space, Modal, Button, Tag, Table, Pagination, Flex, Select, Input, Typography, Popconfirm, Popover } from 'antd';
import { CommentOutlined, EyeFilled, FileTextOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { message } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import UserInfoView from '../../components/community/user_info_view';
import PostReview from '../../components/community/post_review';
import { RichText } from '@chatui/core';
import { ReportTypeColorSets, ReportTypeOptions, ReportStatusOptions } from '../../screens/community/report';
import { conversionUtcDate } from '../../utils/comm';

const ReportView = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currPost, setCurrPost] = useState();
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useState({
        reportType: '',
        userKeyword: '',
        postKeyword: '',
        commentKeyword: '',
        content: '',
        status: '',
    });

    const tabCols = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{conversionUtcDate(text)}</label>
            }
        },
        {
            title: 'Report Type',
            dataIndex: 'reportType',
            key: 'reportType',
            render: (text, _) => {
                return <Tag color={ReportTypeColorSets[text]}>{ReportTypeOptions.find(item => item.value === text).label}</Tag>
            }
        },
        {
            title: 'Report User/Post/Comment',
            dataIndex: 'target',
            key: 'target',
            render: (_, record) => {
                if (record.reportType === 'ReportUser') {
                    return <UserInfoView user={record.reportedUser} showWallet={false} />
                } else if (record.reportType === 'ReportPost') {
                    let post = record.post;
                    return <div style={{ background: '#fafafa', padding: '8px 12px', borderRadius: 8, maxWidth: 240, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className="community-content">
                            <RichText content={post.title ? post.title : post.content} />
                        </div>
                        <EyeFilled style={{ color: 'grey', cursor: 'pointer' }} onClick={() => reviewPost(record)} />
                    </div>
                } else if (record.reportType === 'ReportComment') {
                    let comment = record.comment;
                    return <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, maxWidth: 240, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className="community-content">
                            <RichText content={comment.content} />
                        </div>
                        <Popover
                            placement="right"
                            title=''
                            content={
                                <div className="post-content-review" style={{ maxWidth: 300, maxHeight: 400, overflowY: 'auto' }}>
                                    <RichText content={comment.content} />
                                </div>
                            }
                            trigger={'click'}>
                            <EyeFilled style={{ color: 'grey', cursor: 'pointer' }} />
                        </Popover>
                    </div>
                }
                return '';
            }
        },
        {
            title: 'Issue Desc',
            dataIndex: 'content',
            key: 'content',
            render: (text, _) => {
                return (
                    <div style={{ fontSize: 12, color: 'grey' }}>
                        {text}
                    </div>
                )
            },
        },
        {
            title: 'Report Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if (record.status === 'NoReview') {
                    return <Typography.Text type='warning'>Pending</Typography.Text>
                } else if (record.status === 'Pass') {
                    return <Typography.Text type='success'>Pass</Typography.Text>
                } else if (record.status === 'NoPass') {
                    return <Typography.Text type='danger'>No Pass</Typography.Text>
                }
                return '';
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        {
                            record.status === 'NoReview' && <Popconfirm
                                title='Please handle the report'
                                okText='Pass'
                                cancelText='No Pass'
                                onCancel={() => {
                                    if (record.reportType === 'ReportPost' || record.reportType === 'ReportComment') {
                                        riskHandle(record);
                                    } else {
                                        approveHandle(record, 'NoPass')
                                    }
                                }}
                                onConfirm={() => approveHandle(record, 'Pass')}>
                                <Button type='link'>Approve</Button>
                            </Popconfirm>
                        }
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);
    }, [])

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchReportList(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            newParams[tag] = value;
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const reviewPost = (record) => {
        setCurrPost(record.post);
        setOpen(true);
    }

    const riskHandle = (record) => {
        let reason = '';
        let modal = Modal.confirm({
            closable: false,
            title: 'Are you sure to reject this post or comment?',
            content: (
                <div>
                    <Input style={{ color: 'red' }} prefix={<span style={{ color: '#333333' }}>Reason: </span>} placeholder="reject reason" onChange={evt => {
                        reason = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !reason.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !reason.trim(),
            },
            cancelText: 'Cancel',
            onOk: () => approveHandle(record, 'NoPass', reason),
        })
    }

    const approveHandle = (record, status, riskReason) => {
        setLoading(true);
        let params = {
            list: [{ id: record.id, status, riskReason }]
        };
        updateReport(params).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify="space-between">
                    <div />
                    <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <Select
                            style={{ width: 160 }}
                            options={ReportTypeOptions}
                            placeholder='Report Type'
                            onChange={value => onSearchChange('reportType', value)}
                            allowClear
                        />
                        <Select
                            style={{ width: 160 }}
                            options={ReportStatusOptions}
                            placeholder='Report Status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        {
                            searchParams.reportType === 'ReportUser' && <Input
                                prefix={<UserOutlined />}
                                style={{ width: 180 }}
                                placeholder="User search"
                                allowClear
                                onChange={evt => onSearchChange('userKeyword', evt.target.value)}
                            />
                        }
                        {
                            searchParams.reportType === 'ReportPost' && <Input
                                prefix={<FileTextOutlined />}
                                style={{ width: 180 }}
                                placeholder="Post search"
                                allowClear
                                onChange={evt => onSearchChange('postKeyword', evt.target.value)}
                            />
                        }
                        {
                            searchParams.reportType === 'ReportComment' && <Input
                                prefix={<CommentOutlined />}
                                style={{ width: 180 }}
                                placeholder="Comment search"
                                allowClear
                                onChange={evt => onSearchChange('commentKeyword', evt.target.value)}
                            />
                        }
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <PostReview
                from='report'
                open={open}
                canEdit={false}
                post={currPost}
                onClose={() => setOpen(false)} />
        </div>
    )
}

export default ReportView;