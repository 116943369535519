import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Pagination, Flex, Popconfirm, message } from 'antd';
import {useEffect, useState} from 'react';
import { deleteVersionNote, getVersionNotes } from '../../api/system';
import AppFeatureEditView from './app_feature_edit_view';
import { conversionUtcDate } from '../../utils/comm';

const AppFeaturesView = () => {
    const tabCols = [
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
            render: (text, _) => {
                return <b>{text}</b>
            }
        },
        {
            title: 'Created At',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{conversionUtcDate(text)}</label>
            }
        },
        {
            title: 'Features',
            dataIndex: 'description',
            key: 'description',
            render: (text, _) => {
                return <div style={{whiteSpace: 'pre'}}>{text}</div>
            }
        },
        {
            title: 'Min Version',
            dataIndex: 'minVersion',
            key: 'minVersion'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (_, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this version?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    const [loading, setLoading] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [currVersion, setCurrVersion] = useState();

    useEffect(() => {
        fetchData(pageNum, pageSize);
    }, []);

    const fetchData = (pNum, pSize) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize
        };
        getVersionNotes(params).then(res => {
            if (res.list) {
                setData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false));
    }

    const createNewVersion = () => {
        setCurrVersion(null);
        setOpen(true);
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const editHandle = (record) => {
        setCurrVersion(record);
        setOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteVersionNote(record.id).then(res => {
            fetchData(pageNum, pageSize);
        }).catch(err => {
            message.success(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='main-wrapper'>
            <Flex justify='flex-end' style={{marginBottom: 20, width: '100%'}}>
                <Button type='primary' style={{marginRight: 16}} icon={<PlusOutlined />} onClick={createNewVersion}>Create New App Version</Button>
            </Flex>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={data} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <AppFeatureEditView
                defaultFeature={currVersion}
                open={open}
                onClose={() => setOpen(false)}
                onUpdate={() => {
                    setOpen(false);
                    fetchData(pageNum, pageSize);
                }} />
        </div>
    )
}

export default AppFeaturesView;