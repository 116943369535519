import React, { useMemo } from "react";
import {
    Route,
    HashRouter,
    Routes,
    useLocation,
    Navigate,
    useOutlet,
} from "react-router-dom";
import LoginPanel from "../screens/login";
import MainLayout from "../layout/mainlayout";
import { getCookie } from "../utils/cookie";
import BannerScreen from "../screens/banner";
import BannerDetailScreen from "../screens/banner/detail";
import DiscoverScreen from "../screens/discover";
import DiscoverDetailScreen from "../screens/discover/detail";
import DailyScreen from "../screens/daily";
import CourseScreen from "../screens/course";
import CourseDetailScreen from "../screens/course/detail";
import NewsScreen from "../screens/news";
import NewsDetailScreen from "../screens/news/detail";
import DailyDetailScreen from "../screens/daily/detail";
import CommunityScreen from "../screens/community";
import CommunityDetailScreen from "../screens/community/detail";
import DailyQaScreen from "../screens/daily/qa_index";
import UsersScreen from "../screens/users";
import UserRecommendScreen from "../screens/users/recommend_user";
import TopicScreen from "../screens/community/topic";
import KeepAlive from "keepalive-for-react";
import DashboardScreen from "../screens/dashboard";
import SystemSettingScreen from "../screens/system";
import ReportScreen from "../screens/community/report";
import CommentsScreen from "../screens/community/comment";
import PointProductsScreen from "../screens/points/products";
import PointBalanceScreen from "../screens/points/balances";
import UserLabelScreen from "../screens/users/labels";
import HomeArticleScreen from "../screens/home/hot_article";
import HomeTopicScreen from "../screens/home/hot_topic";
import PointRecordsScreen from "../screens/points/history";

export const MainLayoutUrl = {
    dashboard: {
        index: {
            path: "/dashboard",
            selectedKey: "dashboard",
        },
    },
    banner: {
        index: {
            path: "/banners",
            selectedKey: "banner",
        },
        item: {
            path: "/banners/:id",
            selectedKey: "banner",
        },
    },
    homepage: {
        hotarticle: {
            index: {
                path: "/hotarticle",
                selectedKey: "hotarticle",
            },
        },
        hottag: {
            index: {
                path: "/hottag",
                selectedKey: "hottag",
            },
        },
    },
    discover: {
        index: {
            path: "/discover",
            selectedKey: "discover",
        },
        item: {
            path: "/discover/:id",
            selectedKey: "discover",
        },
    },
    daily: {
        knowledge: {
            index: {
                path: "/knowledge",
                selectedKey: "knowledge",
            },
            item: {
                path: "/knowledge/:id",
                selectedKey: "knowledge",
            },
        },
        question: {
            index: {
                path: "/question",
                selectedKey: "question",
            },
        },
    },
    course: {
        index: {
            path: "/courses",
            selectedKey: "course",
        },
        item: {
            path: "/courses/:course",
            selectedKey: "course",
        },
    },
    news: {
        index: {
            path: "/news",
            selectedKey: "news",
        },
        item: {
            path: "/news/:id",
            selectedKey: "news",
        },
    },
    community: {
        topic: {
            index: {
                path: "/topic",
                selectedKey: "topic",
            },
        },
        post: {
            index: {
                path: "/post",
                selectedKey: "post",
            },
            item: {
                path: "/post/:id",
                selectedKey: "post",
            },
        },
        report: {
            index: {
                path: "/report",
                selectedKey: "report",
            },
        },
        comment: {
            index: {
                path: "/comment",
                selectedKey: "comment",
            },
        },
    },
    users: {
        all: {
            index: {
                path: "/all",
                selectedKey: "all",
            },
        },
        recommenduser: {
            index: {
                path: "/recommenduser",
                selectedKey: "recommenduser",
            },
        },
        ulabels: {
            index: {
                path: "/ulabels",
                selectedKey: "ulabels",
            },
        },
    },
    points: {
        products: {
            index: {
                path: "/products",
                selectedKey: "products",
            },
        },
        balance: {
            index: {
                path: "/balance",
                selectedKey: "balance",
            },
        },
        pointrecords: {
            index: {
                path: "/pointrecords",
                selectedKey: "pointrecords",
            },
        },
    },
    systemconfig: {
        index: {
            path: "/systemconfig",
            selectedKey: "systemconfig",
        },
    }
};

const MainRouters = [
    {
        path: MainLayoutUrl.dashboard.index.path,
        key: "dashboard",
        exact: true,
        element: <DashboardScreen />,
    },
    {
        path: MainLayoutUrl.banner.index.path,
        key: "banner",
        exact: true,
        element: <BannerScreen />,
    },
    {
        path: MainLayoutUrl.banner.item.path,
        key: "bannerItem",
        exact: true,
        element: <BannerDetailScreen />,
    },

    {
        path: MainLayoutUrl.homepage.hotarticle.index.path,
        key: "hotarticle",
        exact: true,
        needCache: true,
        element: <HomeArticleScreen />,
    },
    {
        path: MainLayoutUrl.homepage.hottag.index.path,
        key: "hottag",
        exact: true,
        needCache: true,
        element: <HomeTopicScreen />,
    },

    {
        path: MainLayoutUrl.discover.index.path,
        key: "discover",
        exact: true,
        needCache: true,
        element: <DiscoverScreen />,
    },
    {
        path: MainLayoutUrl.discover.item.path,
        key: "discoverItem",
        exact: true,
        element: <DiscoverDetailScreen />,
    },

    {
        path: MainLayoutUrl.daily.knowledge.index.path,
        key: "daily",
        exact: true,
        element: <DailyScreen />,
    },
    {
        path: MainLayoutUrl.daily.knowledge.item.path,
        key: "dailyItem",
        exact: true,
        element: <DailyDetailScreen />,
    },
    {
        path: MainLayoutUrl.daily.question.index.path,
        key: "question",
        exact: true,
        element: <DailyQaScreen />,
    },

    {
        path: MainLayoutUrl.course.index.path,
        key: "course",
        exact: true,
        element: <CourseScreen />,
    },
    {
        path: MainLayoutUrl.course.item.path,
        key: "courseItem",
        exact: true,
        element: <CourseDetailScreen />,
    },

    {
        path: MainLayoutUrl.news.index.path,
        key: "news",
        exact: true,
        element: <NewsScreen />,
    },
    {
        path: MainLayoutUrl.news.item.path,
        key: "newsItem",
        exact: true,
        element: <NewsDetailScreen />,
    },

    {
        path: MainLayoutUrl.community.topic.index.path,
        key: "topic",
        exact: true,
        needCache: true,
        element: <TopicScreen />,
    },
    {
        path: MainLayoutUrl.community.report.index.path,
        key: "report",
        exact: true,
        needCache: true,
        element: <ReportScreen />,
    },
    {
        path: MainLayoutUrl.community.post.index.path,
        key: "post",
        exact: true,
        needCache: true,
        element: <CommunityScreen />,
    },
    {
        path: MainLayoutUrl.community.comment.index.path,
        key: "comment",
        exact: true,
        needCache: true,
        element: <CommentsScreen />,
    },
    {
        path: MainLayoutUrl.community.post.item.path,
        key: "postItem",
        exact: true,
        element: <CommunityDetailScreen />,
    },

    {
        path: MainLayoutUrl.users.all.index.path,
        key: "all",
        exact: true,
        needCache: true,
        element: <UsersScreen />,
    },
    {
        path: MainLayoutUrl.users.recommenduser.index.path,
        key: "recommenduser",
        exact: true,
        element: <UserRecommendScreen />,
    },
    {
        path: MainLayoutUrl.users.ulabels.index.path,
        key: "ulabels",
        exact: true,
        element: <UserLabelScreen />,
    },
    {
        path: MainLayoutUrl.points.products.index.path,
        key: "products",
        exact: true,
        element: <PointProductsScreen />,
    },
    {
        path: MainLayoutUrl.points.balance.index.path,
        key: "balance",
        exact: true,
        element: <PointBalanceScreen />,
    },
    {
        path: MainLayoutUrl.points.pointrecords.index.path,
        key: "pointrecords",
        exact: true,
        element: <PointRecordsScreen />,
    },
    {
        path: MainLayoutUrl.systemconfig.index.path,
        key: "systemconfig",
        exact: true,
        element: <SystemSettingScreen />,
    },
];

const SystemRouters = () => {
    return (
        <HashRouter>
            <Routes>
                <Route
                    path="/login"
                    exact={true}
                    element={
                        <LoginRoute>
                            <LoginPanel />
                        </LoginRoute>
                    }
                />
                <Route path='/' element={<MainLayout />}>
                    <Route path='/' element={<BasicLayoutWithCache />}>
                        {
                            MainRouters.map((route) => <Route
                                key={route.key}
                                path={route.path}
                                exact={route.exact}
                                element={<PrivateRoute>{route.element}</PrivateRoute>}
                            />)
                        }
                    </Route>
                </Route>
                <Route
                    path="*"
                    element={
                        <Navigate to={MainLayoutUrl.dashboard.index.path} replace={true} />
                    }
                />
            </Routes>
        </HashRouter>
    );
};

const LoginRoute = (props) => {
    const { children } = props;
    const isLoggedIn = getCookie("user-token") ? true : false;
    if (!isLoggedIn) {
        return <>{children}</>;
    }
    return <Navigate replace={true} to="/" />;
};

const PrivateRoute = (props) => {
    const { children } = props;
    const isLoggedIn = getCookie("user-token") ? true : false;
    const location = useLocation();
    if (isLoggedIn) {
        return <>{children}</>;
    }
    return (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}${location.search}` }}
        />
    );
};

const BasicLayoutWithCache = () => {
    const outlet = useOutlet();
    const location = useLocation();

    const currRouter = useMemo(() => {
        let _router = MainRouters.find(item => {
            if (location.pathname.indexOf('/') !== location.pathname.lastIndexOf('/') && item.path.indexOf('/') !== item.path.lastIndexOf('/')) {
                return location.pathname.split('/')[1] === item.path.split('/')[1]
            }
            return item.path === location.pathname;
        });
        return _router
    }, [location]);

    if (!currRouter) {
        return <Navigate to={MainLayoutUrl.dashboard.index.path} replace={true} />;
    }

    return <div>
        <KeepAlive activeName={currRouter?.key} max={10} strategy={'LRU'} cache={currRouter?.needCache ?? false}>
            {outlet}
        </KeepAlive>
    </div>
}

export default SystemRouters;
