import { useEffect, useState, useRef } from 'react'
import { blockUser, fetchUserSearch, fetchUserTags, updateUserVerification, userApplyTag } from '../../api/community';
import { Space, Avatar, Button, Table, Pagination, Flex, Input, Modal, Select, Tooltip, DatePicker, Tag, Popover, message } from 'antd';
import { CheckOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation } from 'react-router-dom';
import ApplicantModalView from '../../components/user/applicant_modal_view';
import UserInfoView from '../../components/community/user_info_view';
import { agentIsAndriod, agentIsIos, conversionUtcDate } from '../../utils/comm';
import AssetsBalanceView from '../../components/user/assets_balance_view';
import { Typography } from 'antd';

export const VerificationTypeOptions = [
    { key: 0, label: 'General', value: 'NormalUser' },
    { key: 1, label: 'Verified User', value: 'VerifiedUser' },
    { key: 2, label: 'Verified Organisation', value: 'VerifiedOrganisation' },
    { key: 3, label: 'Paid User', value: 'PaidUser' },
    { key: 4, label: 'Paid Organisation', value: 'PaidOrganisation' },
]

const UsersScreen = () => {
    const location = useLocation();
    const [tabData, setTabData] = useState([]);
    const [oldUsersTag, setOldUsersTag] = useState([]);
    const [newUsersTag, setNewUsersTag] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState();
    const [open, setOpen] = useState(false);
    const tooltipRef = useRef(null);
    const [labels, setLabels] = useState([]);
    const [popOpen, setPopOpen] = useState(false);
    const [currUserId, setCurrUserId] = useState(null);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Registered',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'User',
            children: [
                {
                    title: 'Basic Info',
                    dataIndex: 'user',
                    key: 'user',
                    render: (_, record) => {
                        return <UserInfoView user={record} showWallet />;
                    }
                },
                {
                    title: 'Username/UserId',
                    dataIndex: 'username',
                    key: 'username',
                    render: (_, record) => {
                        return (
                            <div style={{ width: 240 }}>
                                <Typography.Text copyable><b>{record.username}</b></Typography.Text><br />
                                <Typography.Text copyable style={{ fontSize: 12 }}>{record.userId}</Typography.Text>
                            </div>
                        )
                    }
                },
            ]
        },
        {
            title: 'User Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (text, record) => {
                if (!text || text.length <= 0) return '-';
                return (
                    <Space size={1}>
                        {
                            Array.from(text).map((item, index) => {
                                return (
                                    <Tag key={`${record.userId}_${index}`} color='green'>{item.name}</Tag>
                                )
                            })
                        }
                    </Space>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'blockTime',
            key: 'blockTime',
            render: (text, _) => {
                if (!text) return '-';
                let blockTime = new Date(conversionUtcDate(text)).getTime();
                if (blockTime > Date.now()) return <Tag color='error'>Blocked before <b>{conversionUtcDate(text, null, true)}</b></Tag>;
                return '-'
            }
        },
        {
            title: 'Browsed',
            dataIndex: 'browsedCount',
            key: 'browsedCount',
            render: (text, _) => {
                if (text === '0') return '-';
                return <b>{text}</b>;
            }
        },
        {
            title: 'Followers',
            dataIndex: 'fanCount',
            key: 'fanCount',
            render: (text, _) => {
                if (text === '0') return '-';
                return <b>{text}</b>;
            }
        },
        {
            title: 'Following',
            dataIndex: 'followCount',
            key: 'followCount',
            render: (text, _) => {
                if (text === '0') return '-';
                return <b>{text}</b>;
            }
        },
        {
            title: 'Get Liked',
            dataIndex: 'getLikeCount',
            key: 'getLikeCount',
            render: (text, _) => {
                if (text === '0') return '-';
                return <b>{text}</b>;
            }
        },
        {
            title: 'Last Login',
            children: [
                {
                    title: 'Mobile',
                    dataIndex: 'mobile',
                    key: 'mobile',
                    render: (_, record) => {
                        if (!record.extraUserInfo) return '-'
                        let { lastLoginAgent } = record.extraUserInfo;
                        if (agentIsAndriod(lastLoginAgent)) return <Avatar alt='Android' src='/images/google-play.png' size={20} />;
                        else if (agentIsIos(lastLoginAgent)) return <Avatar alt='Android' src='/images/app-store.png' size={20} />;
                        return '-'
                    }
                },
                {
                    title: 'Provider',
                    dataIndex: 'provider',
                    key: 'provider',
                    render: (_, record) => {
                        if (!record.extraUserInfo) return '-'
                        let { providerIds } = record.extraUserInfo
                        if (!providerIds || providerIds.length <= 0) return '-';
                        return (
                            <Space>
                                {
                                    Array.from(providerIds).map((item, index) => {
                                        let icon = '';
                                        if (item.includes('google')) {
                                            icon = '/images/google.png';
                                        } else if (item.includes('apple')) {
                                            icon = '/images/apple.png';
                                        } else if (item.includes('password')) {
                                            icon = '/images/mail.png';
                                        }
                                        if (icon) {
                                            return <Avatar key={index} src={icon} alt={item} size={20} />;
                                        }
                                        return <div key={index} />
                                    })
                                }
                            </Space>
                        )
                    }
                },
                {
                    title: 'IP',
                    dataIndex: 'ip',
                    key: 'ip',
                    render: (_, record) => {
                        if (!record.extraUserInfo) return '-'
                        let { lastLoginIp } = record.extraUserInfo
                        if (lastLoginIp) return (
                            <div style={{ width: 120 }}>
                                <Typography.Text copyable style={{ fontSize: 12, color: '#afafaf' }}>{lastLoginIp}</Typography.Text>
                            </div>
                        )
                        return '-'
                    },
                },
                {
                    title: 'DateTime',
                    dataIndex: 'lastLoginTime',
                    key: 'lastLoginTime',
                    render: (_, record) => {
                        if (!record.extraUserInfo) return '-'
                        let { lastLoginTime } = record.extraUserInfo
                        if (lastLoginTime) return (
                            <div style={{ fontSize: 12, color: '#afafaf', width: 120 }}>
                                {
                                    conversionUtcDate(lastLoginTime, null, true)
                                }
                            </div>
                        )
                        return '-'
                    }
                },
            ]
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (_, record) => {
                let hasBlock = false;
                if (record.blockTime) {
                    let blockTime = new Date(conversionUtcDate(record.blockTime)).getTime();
                    hasBlock = blockTime > Date.now();
                }
                let needSave = false;
                let oldOne = oldUsersTag.find(item => item.userId === record.userId);
                let newOne = newUsersTag.find(item => item.userId === record.userId);
                if (oldOne && newOne) {
                    if (Array.from(oldOne.tags).sort().join(',') !== Array.from(newOne.tags).sort().join(',')) {
                        needSave = true;
                    }
                }
                return (
                    <Button.Group size='small'>
                        {
                            (record.addressList && record.addressList.length > 0) && (
                                <Popover
                                    placement="topRight"
                                    title={<UserInfoView showWallet={false} user={record} />}
                                    content={
                                        <AssetsBalanceView user={record} />
                                    }
                                    trigger={'click'}>
                                    <Button type='link'>Check Balance</Button>
                                </Popover>
                            )
                        }
                        <Button type='link' onClick={() => { editHandle(record) }}>Verify</Button>
                        {
                            hasBlock ?
                                <Button type='link' onClick={() => { blockHandle(record) }}>Unblock</Button>
                                :
                                <Button danger type='link' onClick={() => { blockHandle(record) }}>Block</Button>
                        }
                        <Popover
                            placement="topRight"
                            title='User Labels'
                            open={popOpen && currUserId === record.userId}
                            content={
                                <Space size={4}>
                                    <Select
                                        mode='multiple'
                                        size='large'
                                        placeholder='select user label'
                                        value={getSelectedValue(record)}
                                        onChange={(value) => onChangeUserLabel(value, record.userId)}
                                        style={{ width: 256 }}
                                        options={labels}
                                    />
                                    {
                                        needSave ?
                                            <Button type='primary' shape='circle' icon={<CheckOutlined />} size='large' onClick={() => onUserLabelSubmit(record.userId)} />
                                            :
                                            null
                                    }
                                </Space>
                            }
                            trigger={'click'}
                            onOpenChange={onOpenChange}>
                            <Button type='link' onClick={() => {
                                setCurrUserId(record.userId);
                                setPopOpen(true);
                            }}>Edit Label</Button>
                        </Popover>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        getLabels();
        getUsers(pageNum, pageSize, keyword);
    }, [location])

    const onOpenChange = (value) => {
        setPopOpen(value);
    }

    const getSelectedValue = (record) => {
        let tags = (newUsersTag ?? []).find(item => item.userId === record.userId).tags;
        tags = tags.map(item => {
            if(item.id) return item.name;
            return item;
        });
        return tags;
    }

    const getLabels = () => {
        fetchUserTags({ 'page.disable': true }).then(res => {
            if (res.list) {
                let tmp = Array.from(res.list).map(item => item.children).reduce((x, y) => Array.from(x).concat(y));
                setLabels(tmp.map(item => ({ label: item.name, value: item.name, key: item.id })));
            }
        });
    }

    const getUsers = useDebouncedCallback(
        (pNum, pSize, keyword) => {
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                'keyword': keyword,
            }
            setCurrUserId(null);
            setPopOpen(false);
            setLoading(true);
            fetchUserSearch(params).then(res => {
                if (res.list) {
                    setTabData(res.list);
                    setOldUsersTag(Array.from(res.list).map(item => ({ userId: item.userId, tags: [...item.tags] })));
                    setNewUsersTag(Array.from(res.list).map(item => ({ userId: item.userId, tags: [...item.tags] })));
                }
                if (res.page) {
                    setTotal(res.page.total);
                }
            }).finally(() => setLoading(false))
        },
        300
    )

    const getVerification = (type, description) => {
        if (type === 'VerifiedUser' || type === 'VerifiedOrganisation') {
            if (description) {
                return (
                    <Tooltip ref={tooltipRef} title={description}>
                        <div>
                            <Avatar size={16} src={type === 'VerifiedUser' ? '/images/personal.png' : '/images/company.png'} />
                        </div>
                    </Tooltip>
                )
            }
            return <Avatar size={16} src={type === 'VerifiedUser' ? '/images/personal.png' : '/images/company.png'} />
        }
        return <div />
    }

    const onChangeUserLabel = (labels, userId) => {
        let tmp = [...newUsersTag];
        let user = tmp.find(item => item.userId === userId);
        user.tags = labels;
        setNewUsersTag(tmp);
    }

    const onUserLabelSubmit = (userId) => {
        setLoading(true);
        let user = newUsersTag.find(item => item.userId === userId);
        userApplyTag({ userId: userId, list: Array.from(user.tags).map(item => ({ name: item })) }).then(res => {
            getUsers(pageNum, pageSize, keyword);
        }).catch(err => {
            setLoading(false);
            message.error(err.message);
        })
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getUsers(page, size, keyword);
    }

    const editHandle = (record) => {
        let verify = (record.certifications ?? [])[0]?.type;
        let description = (record.certifications ?? [])[0]?.description;
        let src = record.picture ?? record.avatar;
        let modal = Modal.confirm({
            width: 600,
            closable: false,
            title: 'User Verification',
            content: (
                <div>
                    <div style={{ marginTop: 32 }} />
                    <Space size={4} style={{ justifyContent: 'center', width: '100%' }}>
                        {
                            src ?
                                <Avatar size={48} src={src} />
                                :
                                <Avatar size={48} icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
                        }
                        <div>
                            <Flex align='center'>
                                <b style={{ marginRight: 4 }}>{record.name ? record.name : record.email}</b>
                                {getVerification(verify, description)}
                            </Flex>
                            <div style={{ color: '#afafaf', fontSize: 12 }}>{record.name ? record.email : ''}</div>
                        </div>
                    </Space>
                    <div style={{ marginTop: 32 }} />
                    <Flex align='center'>
                        <div style={{ width: 120, textAlign: 'right', marginRight: 10 }}>User Type:</div>
                        <Select
                            style={{ width: 300 }}
                            options={VerificationTypeOptions}
                            defaultValue={verify}
                            placeholder='select user type'
                            onChange={value => {
                                verify = value;
                                modal.update({
                                    okButtonProps: {
                                        disabled: !verify?.trim(),
                                    }
                                })
                            }}
                            allowClear
                        />
                    </Flex>
                    <div style={{ marginTop: 12 }} />
                    <Flex align='center'>
                        <div style={{ width: 120, textAlign: 'right', marginRight: 10 }}>Description:</div>
                        <Input style={{ width: 300 }} defaultValue={description} placeholder="verification description" onChange={evt => {
                            description = evt.target.value;
                        }} allowClear />
                    </Flex>
                    <div style={{ marginTop: 20 }} />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !verify?.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { userId: record.userId, list: [{ type: verify === 'NormalUser' ? '' : verify, description: description }] };
                await updateUserVerification(params);
                getUsers(pageNum, pageSize, keyword);
            }
        })
    }

    const blockHandle = (record) => {
        let blockTime = '';
        let verify = (record.certifications ?? [])[0]?.type;
        let description = (record.certifications ?? [])[0]?.description;
        let src = record.picture ?? record.avatar;
        let modal = Modal.confirm({
            width: 600,
            closable: false,
            title: 'Block User',
            content: (
                <div>
                    <div style={{ marginTop: 32 }} />
                    <Space size={4} style={{ justifyContent: 'center', width: '100%' }}>
                        {
                            src ?
                                <Avatar size={48} src={src} />
                                :
                                <Avatar size={48} icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
                        }
                        <div>
                            <Flex align='center'>
                                <b style={{ marginRight: 4 }}>{record.name ? record.name : record.email}</b>
                                {getVerification(verify, description)}
                            </Flex>
                            <div style={{ color: '#afafaf', fontSize: 12 }}>{record.name ? record.email : ''}</div>
                        </div>
                    </Space>
                    <div style={{ marginTop: 32 }} />
                    <Flex align='center'>
                        <div style={{ width: 120, textAlign: 'right', marginRight: 10 }}> Set Block Time:</div>
                        <DatePicker
                            style={{ width: 300 }}
                            variant="filled"
                            showTime
                            format={(value) => `${value ? conversionUtcDate(value) : ''}`}
                            placeholder="Choose block time"
                            onChange={(_, date) => {
                                blockTime = date;
                                modal.update({
                                    okButtonProps: {
                                        disabled: !blockTime?.trim(),
                                    }
                                })
                            }}
                        />
                    </Flex>
                    <div style={{ marginTop: 20 }} />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !blockTime?.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { userId: record.userId, blockTime: conversionUtcDate(blockTime, 'UTC') };
                await blockUser(params);
                getUsers(pageNum, pageSize, keyword);
            }
        })
    }

    const onKeywordChange = (value) => {
        setKeyword(value);
        setPageNum(1);
        getUsers(1, pageSize, value);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button onClick={() => setOpen(true)}><SearchOutlined /> Verification Applicants</Button>
                    <Space>
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onKeywordChange(evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table bordered scroll={{ x: true }} loading={loading} rowKey={(record) => record.userId} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
            <ApplicantModalView open={open} onClose={() => setOpen(false)} />
        </div>
    )
}

export default UsersScreen;