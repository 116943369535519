import cookie from '../utils/cookie';
import { message } from 'antd';
import axios from 'axios';
import { getBaseApi } from './base_url';
import { refreshJWTToken } from '../firebase/auth';

const BASE_URL = `https://${getBaseApi()}/api/admin`;

function post(url, params, appCheckToken = null) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
        }
        if (url.includes('googleapis')) {
            headers['Content-Type'] = 'application/json';
            // headers['Content-Type'] = 'application/x-www-form-urlencoded';
        } else {
            let token = cookie.getCookie('user-token');
            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }
        }

        if (appCheckToken) {
            headers['X-Firebase-Appcheck'] = appCheckToken;
        }

        let fullUrl = url.includes('googleapis') ? url : (BASE_URL + url);
        axios.post(fullUrl, params, { headers, timeout: 60000 }).then(response => {
            const res = response.data;
            if (response.status == 200) {
                resolve(res);
            }
            else {
                message.warning(response.status + '错误', 2)
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication();
            } else if (url.includes('googleapis')) {
                resolve(false);
            } else {
                reject(err);
            }
        });
    })
}

function get(url, params) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let token = cookie.getCookie('user-token');
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.get(BASE_URL + url, { params, headers }, { timeout: 60000 }).then(response => {
            if (response.status == 200) {
                const res = response.data;
                resolve(res);
            }
            else {
                message.warning(response.status + '错误', 2)
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication();
            } else {
                reject(err);
            }
        });
    })
}

function patch(url, params) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
        }
        let token = cookie.getCookie('user-token');
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.patch(BASE_URL + url, params, { headers: headers }, { timeout: 60000 }).then(response => {
            if (response.status == 200) {
                const res = response.data;
                resolve(res);
            }
            else {
                message.warning(response.status + '错误', 2)
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication();
            } else {
                reject(err);
            }
        });
    })
}

function dataDel(url) {
    return new Promise(resolve => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let token = cookie.getCookie('user-token');
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.delete(BASE_URL + url, { params: {}, headers: headers, timeout: 60000 }).then(response => {
            if (response.status == 200) {
                const res = response.data;
                resolve(res);
            }
            else {
                message.warning(response.status + '错误', 2)
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication();
            }
        })
    })
}

export function reAuthentication() {
    refreshJWTToken(() => {
        window.location.reload();
    }, (err) => {
        // alert(String(err));
        cookie.deleteCookie('user-token');
        window.localStorage.removeItem('rjwt');
        window.location.reload();
    });
}

export default {
    post,
    get,
    patch,
    dataDel
}
