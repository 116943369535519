import React from "react";
import {
    AppstoreOutlined,
    GlobalOutlined,
    CarryOutOutlined,
    BookOutlined,
    InstagramOutlined,
    UserOutlined,
    HomeOutlined,
    CommentOutlined,
    WechatOutlined,
    UserSwitchOutlined,
    QuestionCircleOutlined,
    HighlightOutlined,
    TeamOutlined,
    UsergroupAddOutlined,
    TagsOutlined,
    DashboardOutlined,
    ControlOutlined,
    IssuesCloseOutlined,
    MessageOutlined,
    StarFilled,
    ProductOutlined,
    TrademarkCircleOutlined,
    SolutionOutlined,
    SnippetsOutlined,
    FlagOutlined,
    ReconciliationOutlined,
} from "@ant-design/icons";
import '../style/layout.css';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { MainLayoutUrl } from '../router';
import { Avatar, Popover, Button, Layout, Menu, ConfigProvider, Space } from "antd";
import { deleteCookie } from '../utils/cookie';
import { Breadcrumb } from "antd/es";
import { Typography } from "antd";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

function getBreadItems(path, allItems) {
    let pathItems = String(path).split('/').filter(item => item);
    let arr = pathItems.map((e) => {
        let keyStr = Object.keys(MainLayoutUrl).find((rt) => {
            if (MainLayoutUrl[rt].index) {
                return MainLayoutUrl[rt].index.path.includes(e)
            }
            return Object.keys(MainLayoutUrl[rt]).some(ch => MainLayoutUrl[rt][ch].index.path.includes(e));
        });
        if (keyStr) {
            if (MainLayoutUrl[keyStr].index) {
                let key = MainLayoutUrl[keyStr].index.selectedKey;
                let item = allItems.find((item) => item.key === key);
                return {
                    title: item.label,
                    path: MainLayoutUrl[keyStr].index.path,
                }
            } else {
                let chKey = Object.keys(MainLayoutUrl[keyStr]).find(item => MainLayoutUrl[keyStr][item].index.path.includes(e));
                if (chKey) {
                    let item = allItems.find((item) => item.key === keyStr).children.find(item => item.key === chKey);
                    return {
                        title: item.label,
                        path: MainLayoutUrl[keyStr][chKey].index.path,
                    }
                }
            }
        }
        return {
            title: e,
        }
    });
    return [{ title: <HomeOutlined /> }, ...arr];
}

const MainLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const items = [
        getItem('Dashboard', 'dashboard', <DashboardOutlined />),
        getItem('Banners', 'banner', <AppstoreOutlined />),
        getItem('Homepage', 'homepage', <HomeOutlined />, [
            getItem('Hot Article', 'hotarticle', <SnippetsOutlined />),
            getItem('Hot Topic', 'hottag', <FlagOutlined />),
        ]),
        getItem('Discover', 'discover', <InstagramOutlined />),
        getItem('Daily', 'daily', <CarryOutOutlined />, [
            getItem('Daily Knowledge', 'knowledge', <HighlightOutlined />),
            getItem('Daily Q&A', 'question', <QuestionCircleOutlined />),
        ]),
        getItem('Course', 'course', <BookOutlined />),
        getItem('Platform News', 'news', <GlobalOutlined />),
        getItem('Community', 'community', <CommentOutlined />, [
            getItem('Post', 'post', <WechatOutlined />),
            getItem('Bounty Topic', 'topic', <TagsOutlined />),
            getItem('Comment', 'comment', <MessageOutlined />),
            getItem('Report Case', 'report', <IssuesCloseOutlined />),
        ]),
        getItem('Users', 'users', <TeamOutlined />, [
            getItem('All', 'all', <UsergroupAddOutlined />),
            getItem('Recommended', 'recommenduser', <UserSwitchOutlined />),
            getItem('User Labels', 'ulabels', <SolutionOutlined />),
        ]),
        getItem('Task Center', 'points', <StarFilled />, [
            getItem('Products', 'products', <ProductOutlined />),
            getItem('Point Balance', 'balance', <TrademarkCircleOutlined />),
            getItem('Point Records', 'pointrecords', <ReconciliationOutlined />),
        ]),
        getItem('System Config', 'systemconfig', <ControlOutlined />),
    ];

    const breadItems = getBreadItems(location.pathname, items);

    const menuChangeHandle = (evt) => {
        let paths = evt.keyPath;
        let targtPath;
        for (let i = paths.length - 1; i >= 0; i--) {
            if (!targtPath) {
                targtPath = MainLayoutUrl[paths[i]]
            } else {
                targtPath = targtPath[paths[i]]
            }
        }
        navigate(targtPath.index.path);
    }

    const logoutHandle = () => {
        deleteCookie('user-token');
        window.localStorage.removeItem('rjwt');
        window.location.reload();
    }

    const defaultKey = () => {
        let key = '';
        let allKeys = Object.keys(MainLayoutUrl);
        for (let index in allKeys) {
            if (location.pathname.includes(allKeys[index])) {
                key = allKeys[index];
                break;
            } else {
                let subKeys = Object.keys(MainLayoutUrl[allKeys[index]]);
                key = subKeys.find(item => location.pathname.includes(item));
                if (key) break;
            }
        }
        return key;
    };

    const defaultOpenKey = () => {
        let key = Object.keys(MainLayoutUrl).find(item => location.pathname.includes(item) || Object.keys(MainLayoutUrl[item]).some(ch => location.pathname.includes(ch)));
        return key;
    }

    const AccountPanel = () => {
        return (
            <div>
                <Button onClick={logoutHandle} type="text">Sign out</Button>
            </div>
        );
    }

    return (
        <ConfigProvider theme={{
            token: {
                colorTextDisabled: '#666',
                colorBgContainerDisabled: '#f5f5f5'
            },
            components: {
                Menu: {
                    darkItemSelectedBg: 'rgba(255, 255, 255, 0.15)'
                },
                Layout: {
                    headerBg: '#002766',
                },
                Button: {
                    borderRadius: 0
                }
            }
        }}>
            <Layout style={{ height: '100vh' }}>
                <Header className="wrapper-header" style={{ padding: 0 }}>
                    <div className="inc-header">
                        <img src="/images/logo.png" style={{ width: 36, height: 36, marginRight: 8, borderRadius: 20 }} />
                        Go CMS
                    </div>
                    <div style={{ marginRight: 20 }}>
                        <Popover trigger={'click'} placement="bottomRight" content={AccountPanel}>
                            <Space size={4}>
                                <Avatar size={24} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                <Typography.Text style={{color: 'white'}}>Hello, Admin</Typography.Text>
                            </Space>
                        </Popover>
                    </div>
                </Header>
                <Layout>
                    <Sider
                        theme="dark"
                        width={240}
                        breakpoint="lg"
                        collapsedWidth="0"
                        onBreakpoint={broken => {
                            console.log(broken);
                        }}
                        onCollapse={(collapsed, type) => {
                            console.log(collapsed, type);
                        }}
                    >
                        <div style={{ height: 24 }} />
                        <Menu
                            items={items}
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={[defaultKey()]}
                            defaultOpenKeys={[defaultOpenKey()]}
                            onClick={menuChangeHandle}
                        />
                    </Sider>
                    <Layout>
                        <Breadcrumb style={{ margin: '16px 16px 0 16px' }} items={breadItems} />
                        <Content style={{ margin: '24px 16px 0', overflowY: 'auto' }} className={location.pathname === MainLayoutUrl.dashboard.index.path ? '' : 'site-layout-background'}>
                            <Outlet />
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>Go Wallet©2024 Created by ADDX</Footer>
                    </Layout>
                </Layout>
            </Layout>
        </ConfigProvider>
    )
}

export default MainLayout;