import { useEffect, useState } from "react";
import { deleteHomePageHotArticle, fetchHomePageHotArticle } from "../../api/homepage";
import { Card, Image, Flex, Button, Avatar, Space, Badge, Popconfirm, message, Spin, Descriptions, Tag } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { htmlImgSrc } from "../../utils/comm";
import HotArticleEditView from "../../components/homepage/hot_article_edit_view";
import PostReview from "../../components/community/post_review";

const HomeArticleScreen = () => {
    const ARTICLE_NUM = 3;
    const [articles, setArticles] = useState([]);
    const [open, setOpen] = useState(false);
    const [currArticle, setCurrArticle] = useState(null);
    const [reviewOpen, setReviewOpen] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        let params = {
            'page.num': 1,
            'page.size': ARTICLE_NUM,
        }
        fetchHomePageHotArticle(params).then(res => {
            if (res.list) {
                setArticles(res.list);
            }
        })
    }

    const getAddedPanel = () => {
        if (articles.length >= 3) {
            return <div />
        }
        return (
            <Card
                hoverable
                style={{ width: 320 }}
                cover={<Image preview={false} style={{ width: 320, height: 180, objectFit: 'cover' }} fallback="/images/placeholder.svg" />}>
                <Card.Meta title={
                    <Flex justify="center">
                        <Button type="dashed" shape="round" size="large" icon={<PlusOutlined />} onClick={() => editHandle(null)}>
                            Add Article
                        </Button>
                    </Flex>
                }
                    description={<div style={{ color: 'white' }}>AD</div>} />
            </Card>
        )
    }

    const editHandle = (record) => {
        setCurrArticle(record);
        setOpen(true);
    }

    const deleteHandle = (record) => {
        record.isLoading = true;
        setArticles([...articles]);
        deleteHomePageHotArticle(record.id).then(res => {
            getData();
        }).catch(err => {
            message.error(err.message);
            record.isLoading = false;
            setArticles([...articles]);
        })
    }

    const reviewHandle = (record) => {
        setCurrArticle(record);
        setReviewOpen(true);
    }

    const getArticlePanels = () => {
        return (
            <>
                {
                    articles.map((item, index) => {
                        let avatar = '/images/avatar_default.svg';
                        const { user } = item.post;
                        if (user.avatar || user.picture) {
                            avatar = user.picture ?? user.avatar;
                        } else if (user.email.includes('addxgo.io')) {
                            avatar = '/images/logo.png';
                        }
                        let imgs = htmlImgSrc(item.post.content).map(src => src.replaceAll("&amp;", "&"));
                        let nickName = 'Anonymous';
                        if (user.name || user.username) {
                            nickName = user.name ? user.name : user.username;
                        }
                        let color = '#f5222d';
                        if (index === 1) {
                            color = '#fa8c16';
                        } else if (index === 2) {
                            color = '#d4b106';
                        }
                        return (
                            <div key={item.id} style={{ position: 'relative' }}>
                                <Badge.Ribbon text={item.weight} color={color}>
                                    <Card
                                        hoverable
                                        style={{ width: 320 }}
                                        cover={
                                            <Image
                                                preview={false}
                                                style={{ width: 320, height: 180, objectFit: 'cover' }}
                                                fallback="/images/placeholder.svg"
                                                src={imgs.length > 0 ? imgs[0] : null} />
                                        } >
                                        <Card.Meta
                                            avatar={<Avatar src={avatar} size={44} />}
                                            title={item.post.title}
                                            description={
                                                <Flex justify="space-between" align="center">
                                                    <div>{nickName}</div>
                                                    {
                                                        item.isLoading === true ?
                                                            <Spin indicator={<LoadingOutlined spin />} /> :
                                                            <Space size={0}>
                                                                <Button
                                                                    shape="circle"
                                                                    type="text"
                                                                    icon={<EyeOutlined style={{ color: '#afafaf' }} />}
                                                                    onClick={() => reviewHandle(item)} />
                                                                <Button
                                                                    shape="circle"
                                                                    type="text"
                                                                    icon={<EditOutlined style={{ color: '#afafaf' }} />}
                                                                    onClick={() => editHandle(item)} />
                                                                <Popconfirm
                                                                    title="Delete Action"
                                                                    description="Are you sure to delete this hot article?"
                                                                    onConfirm={() => { deleteHandle(item) }}
                                                                    okText="Yes"
                                                                    cancelText="No" >
                                                                    <Button shape="circle" type="text" icon={<DeleteOutlined style={{ color: '#afafaf' }} />} />
                                                                </Popconfirm>
                                                            </Space>
                                                    }
                                                </Flex>
                                            } />
                                    </Card>
                                </Badge.Ribbon>
                                <Tag color={String(item.status).toLowerCase() === 'active' ? 'green' : 'red'} style={{ position: 'absolute', left: 10, top: 10 }}>{item.status}</Tag>
                            </div>
                        )
                    })
                }
            </>
        )
    }
    return (
        <div style={{ padding: 20 }}>
            <Descriptions title='Hot Articles'>
                <Descriptions.Item>
                    <Space size={24} wrap>
                        {getArticlePanels()}
                        {getAddedPanel()}
                    </Space>
                </Descriptions.Item>
            </Descriptions>
            <HotArticleEditView
                open={open}
                defaultArticle={currArticle}
                onClose={() => setOpen(false)}
                onUpdate={getData} />
            <PostReview
                from="ha"
                open={reviewOpen}
                post={currArticle ? currArticle.post : null}
                onClose={() => setReviewOpen(false)}
                canEdit={false} />
        </div>
    );
}

export default HomeArticleScreen;