import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';
import { BucketType, firebaseConfig } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { message } from 'antd';
import { getImageBucket } from "../../utils/base_url";


const RichTextEditor = ({ contentField, defaultHeight, defaultContent, onUpdate }) => {
    const editorRef = useRef(null);
    return <Editor
        id={contentField}
        value={defaultContent}
        init={{
            height: defaultHeight,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
                'undo redo | formatselect |fontselect fontsizeselect | bold italic backcolor forecolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
            file_browser_callback_types: 'image',
            file_picker_callback: function (cb, value, meta) {
                var input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");
                input.onchange = function () {
                    var file = this.files[0];
                    let storage = firebaseConfig(BucketType.image);
                    const storageRef = ref(storage, `/post/${encodeURIComponent(file.name.replace(/[ !@#$%^&*(),?":{}|<>]/g, '-'))}`);
                    const uploadTask = uploadBytesResumable(storageRef, file);
                    message.loading('Image uploading...');
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {},
                        (err) => {
                            message.destroy();
                            message.error(String(err));
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                let updated = url;
                                if (updated.includes('/o/')) {
                                    updated = 'https://' + getImageBucket() + '/' + decodeURIComponent(updated.split('?')[0]).split('/o/')[1];
                                }
                                message.destroy();
                                cb(updated, { title: file.name });
                            }).catch((err) => {
                                message.destroy();
                                message.error(String(err));
                            });
                        }
                    );
                };
                input.click();
            },
        }}
        onInit={(evt, editor) => {
            editorRef.current = editor;
        }}
        onEditorChange={(data, _) => onUpdate(contentField, data)}
    />
}

export default RichTextEditor;