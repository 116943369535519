import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, message, Popconfirm, Image, Flex, Modal, Input } from 'antd';
import { PlusOutlined, PlayCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { MainLayoutUrl } from '../../router';
import { fetchNews, deleteNews, updateNews } from '../../api/news';
import dayjs from 'dayjs';

const NewsScreen = (props) => {
    const navigate = useNavigate();
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                if(parseInt(text) > 0) return <b>{text}</b>;
                return '';
            }
        },
        {
            title: 'Resource',
            dataIndex: 'resource',
            key: 'resource',
            render: (text, _) => {
                if (!text) return <div />;
                if (String(text).includes('.mp4')) {
                    return (
                        <div style={{ display: 'flex' }}>
                            <div style={{ position: 'relative' }}>
                                <video style={{ maxWidth: 160, maxHeight: 72, objectFit: 'contain' }} autoPlay={false} src={text} />
                                <Flex justify='center' align='center' style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>
                                    <PlayCircleFilled onClick={() => window.open(text, '_blank')} style={{fontSize: 18, color: '#cd201f', background: 'white', borderRadius: '50%', cursor: 'pointer'}} />
                                </Flex>
                            </div>
                        </div>
                    )
                }
                return (
                    <Image src={text} style={{ maxWidth: 160, maxHeight: 72, objectFit: 'contain' }} />
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Button type='link' onClick={() => { recommendHandle(record) }}>Recommend</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this news?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchData(pageNum, pageSize);
    }, []);

    const fetchData = (pNum, pSize) => {
        let params = { 'page.num': pNum, 'page.size': pSize };
        fetchNews(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false));
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const createNewNews = () => {
        navigate(MainLayoutUrl.news.item.path.replace(':id', '0'));
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteNews(record.id).then(res => {
            message.success('deleted!');
            fetchData(pageNum, pageSize);
        }).catch(err => message.error(String(err))).finally(() => setLoading(false));
    }

    const editHandle = (record) => {
        navigate(MainLayoutUrl.news.item.path.replace(':id', record.id), { state: { detail: JSON.stringify(record) } })
    }

    const recommendHandle = (record) => {
        let weight = String(record.weight);
        let modal = Modal.confirm({
            closable: false,
            title: 'Recommend the post?',
            content: (
                <div>
                    <Input defaultValue={weight} type="number" prefix={'Recommend Weight: '} placeholder="weight" onChange={evt => {
                        weight = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !weight.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !weight.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { ...record, weight: weight };
                delete params['createdAt'];
                await updateNews(params);
                fetchData(pageNum, pageSize);
            }
        })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={createNewNews}>Publish News</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default NewsScreen;